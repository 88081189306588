import Nav from "./components/Nav";
import Footer from "./components/Footer";
import "./styles/styles.scss";
import Router, { ScrollToTop } from "./Router";
import CookiesBanner from "./components/CookiesBanner";
import Modals from "./modals";
import Modal from "./components/generic/Modal";

export default function App() {
  return (
    <>
      <Nav />
      <div className="nav-margin"></div>
      <Router />
      <Footer />
      <CookiesBanner />
      <Modals />
      <ScrollToTop />
      <Modal />
    </>
  );
}
