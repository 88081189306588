import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { deleteBacheca } from "../../services/bacheca";
import { useAppDispatch } from "../../services/redux";
import {
  selectBachecaDeleteModalOpen,
  setIsBachecaDeleteModalOpen,
} from "../../store/appSlice";
import Button from "../dom/Button";

export default function BachecaDeleteModal() {
  const data = useSelector(selectBachecaDeleteModalOpen);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [isLocaleLoading, setIsLocaleLoading] = useState(false);

  if (!data) return <></>;

  const deleteBachecaButton = async (id: string) => {
    setIsLocaleLoading(true);
    try {
      await deleteBacheca(id);
      data.callback();
      dispatch(setIsBachecaDeleteModalOpen(undefined));
    } catch (error) {
      alert(error);
    }

    setIsLocaleLoading(false);
  };

  return (
    <div
      className="custom-modal-container"
      onClick={() => dispatch(setIsBachecaDeleteModalOpen(undefined))}
    >
      <div className="custom-modal">
        <p className="my-5">
          {t("settings.confirm_delete")} {data.bacheca.name}
        </p>

        <div
          onClick={() => dispatch(setIsBachecaDeleteModalOpen(undefined))}
          className="close"
        >
          X
        </div>
        <div className="actions">
          <Button
            onClick={() => deleteBachecaButton(data.bacheca._id)}
            loading={isLocaleLoading}
            error
            text={t("settings.delete")}
          />
        </div>
      </div>
    </div>
  );
}
