import facebookSvg from "../assets/svg/facebook.svg";
import instagramSvg from "../assets/svg/instagram.svg";
import linkedinSvg from "../assets/svg/linkedin.svg";

interface Props {
  light?: boolean;
}

export default function Socials({ light }: Props) {
  const socials: Array<{ image: string; link: string }> = [
    // {
    //   image: facebookSvg,
    //   link: "",
    // },
    // {
    //   image: instagramSvg,
    //   link: "",
    // },
    // { image: linkedinSvg, link: "" },
  ];

  return (
    <div className={`w100 d-flex d-md-block justify-content-center`}>
      {socials.map((s, key) => {
        return (
          <a
            className={`social-link ${light ? "light" : ""}`}
            href={s.link}
            target="_blank"
            rel="noreferrer"
            key={"social_" + key}
          >
            <img src={s.image} alt="socials" />
          </a>
        );
      })}
    </div>
  );
}
