import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "./dom/Button";
import { resetPassword, sendRecoveryPasswordEmail } from "../services/auth";

const DELAY_MAIL_SECONDS = 60;

interface Props {
  passwordResetSuccess: Function;
}

export default function PasswordRecovery({ passwordResetSuccess }: Props) {
  const [token, setToken] = useState<string | null>();
  const { t } = useTranslation();
  const [emailSent, setEmailSent] = useState("");
  const [isLocaleLoading, setIsLocaleLoading] = useState(false);
  const [timeDelay, setTimeDelay] = useState(0);

  const _resetPasswordModeInit = () => {
    let params = new URL(window.location as any).searchParams;
    let token = params.get("token");

    setToken(token);
  };

  useEffect(() => {
    _resetPasswordModeInit();
  }, [token]);

  const sendRecoveryEmailForm = async (e: any) => {
    setIsLocaleLoading(true);
    e.preventDefault();

    const email = e?.target?.email?.value ?? emailSent;

    try {
      if (!email) throw new Error("Email not set");

      await sendRecoveryPasswordEmail(email);
      setEmailSent(email);
      setTimeDelay(DELAY_MAIL_SECONDS);

      const interval = setInterval(() => {
        setTimeDelay((delay) => {
          if (delay <= 1) {
            clearInterval(interval);
            setEmailSent("");
          }

          return delay - 1;
        });
      }, 1000);
    } catch (error) {
      alert(error);
    }
    setIsLocaleLoading(false);
  };

  const resetPasswordWithTokenForm = async (e: any) => {
    setIsLocaleLoading(true);

    e.preventDefault();

    const password = e.target.password.value;

    try {
      if (!password || !token) throw new Error("password or token not set");

      await resetPassword(token, password);
      passwordResetSuccess();
    } catch (error) {
      alert(error);
    }
    setIsLocaleLoading(false);
  };

  if (token)
    return (
      <section>
        <form onSubmit={resetPasswordWithTokenForm}>
          <p className="text-center info">{t("auth.info_recovery_password_new")}</p>
          <label htmlFor="password">Password</label>
          <input required type="password" name="password" id="password" />
          <Button
            disabled={isLocaleLoading}
            loading={isLocaleLoading}
            type="submit"
            text={t("auth.reset_password")}
          />
        </form>
      </section>
    );

  return (
    <section className="recovery-password">
      <form onSubmit={sendRecoveryEmailForm}>
        <p className="text-center info">{t("auth.info_recovery_password_email")}</p>
        <label htmlFor="email">Email</label>
        <input
          disabled={emailSent ? true : false}
          required
          type="email"
          name="email"
          id="email"
        />
        <Button
          disabled={emailSent ? true : false}
          type="submit"
          loading={isLocaleLoading}
          text={emailSent ? t("auth.send_again") : t("auth.send")}
          metadata={timeDelay > 0 ? timeDelay : ""}
        />
      </form>
    </section>
  );
}
