import { debounce } from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { iUser } from "../../services/auth";
import { APPROVAL_RULE } from "../../services/bacheca";
import {
  getContentsOfBacheca,
  iContent,
  patchContent,
  setContentApprovalStatus,
} from "../../services/content";
import { useAppDispatch } from "../../services/redux";
import { setIsContentDeleteModalOpen } from "../../store/appSlice";
import { selectUser } from "../../store/userSlice";
import Button from "../dom/Button";
import DropdownMenu from "../dom/DropdownMenu";
import Loader from "../Loader";

interface ContentProp {
  content: iContent;
  editors: Array<string | iUser>;
  onUpdate: Function;
}

function Content({ content, editors, onUpdate }: ContentProp) {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const user = useSelector(selectUser);
  const [isLocaleLoading, setIsLocaleLoading] = useState(false);
  const [_content, _setContent] = useState<iContent>();

  useEffect(() => {
    _setContent(content);
    // eslint-disable-next-line
  }, []);

  const approve = async () => {
    if (isLocaleLoading) return;
    if (!_content) return;

    setIsLocaleLoading(true);

    try {
      await setContentApprovalStatus({
        content_id: _content._id,
        approved_status: APPROVAL_RULE.APPROVED,
      });
      _setContent((c) => {
        c!.approvedStatus = APPROVAL_RULE.APPROVED;
        return c;
      });
    } catch (error) {
      console.log(error);
    }

    setIsLocaleLoading(false);
  };

  const show = async () => {
    if (isLocaleLoading) return;
    if (!_content) return;

    setIsLocaleLoading(true);

    try {
      await patchContent({
        content_id: _content._id,
        hidden: "off",
      });
      _setContent((c) => {
        c!.hidden = false;
        return c;
      });
    } catch (error) {
      console.log(error);
    }

    setIsLocaleLoading(false);
  };

  const hide = async () => {
    if (isLocaleLoading) return;
    if (!_content) return;

    setIsLocaleLoading(true);

    try {
      await patchContent({
        content_id: _content._id,
        hidden: "on",
      });
      _setContent((c) => {
        c!.hidden = true;
        return c;
      });
    } catch (error) {
      console.log(error);
    }

    setIsLocaleLoading(false);
  };

  const removeApproval = async () => {
    if (isLocaleLoading) return;
    if (!_content) return;

    setIsLocaleLoading(true);

    try {
      await setContentApprovalStatus({
        content_id: _content._id,
        approved_status: APPROVAL_RULE.TO_APPROVE,
      });
      _setContent((c) => {
        c!.approvedStatus = APPROVAL_RULE.TO_APPROVE;
        return c;
      });
    } catch (error) {
      console.log(error);
    }

    setIsLocaleLoading(false);
  };

  const isEditor = () => {
    if (editors?.includes(user._id)) return true;
    if (user.isAdmin) return true;
    return false;
  };

  return (
    <div className="content-block">
      <div
        style={{
          backgroundImage: `url(${_content?.images[0]})`,
          backgroundPosition: "center center",
          backgroundSize: "cover",
          height: `${Math.floor(Math.random() * (450 - 250 + 1) + 200)}px`,
        }}
        onClick={() => navigate("/content/" + _content?._id)}
        className="image cursor-pointer"
      >
        {_content?.approvedStatus === APPROVAL_RULE.TO_APPROVE ? (
          <div className="content-metadata approval">
            <span>{t("content_card.not_approved")}</span>
            {isEditor() ? (
              <Button
                onClick={(e: any) => {
                  e.stopPropagation();
                  approve();
                }}
                text={t("content_card.approve")}
                small
              />
            ) : null}
          </div>
        ) : null}
        {_content?.hidden ? (
          <div className="content-metadata show">
            <span>{t("content_card.hidden")}</span>
            <Button
              onClick={(e: any) => {
                e.stopPropagation();
                show();
              }}
              text={t("content_card.show")}
              small
            />
          </div>
        ) : null}

        {_content &&
        ((_content?.user as iUser)?._id === user._id || isEditor()) ? (
          <DropdownMenu
            position="left"
            hovered
            className="content-settings"
            options={[
              {
                text: "Edit",
                hidden: !isEditor(),
                action: () =>
                  navigate(
                    `/bacheca/${content.bacheca}/content/edit/${content._id}`
                  ),
              },
              {
                text: "Remove Approval",
                hidden: !isEditor(),
                action: () => removeApproval(),
              },
              { text: "hide", action: () => hide() },
              {
                text: "delete",
                red: true,
                action: () => {
                  dispatch(
                    setIsContentDeleteModalOpen({
                      content: content,
                      callback: () => onUpdate?.(),
                    })
                  );
                },
              },
            ]}
          />
        ) : null}
        {isLocaleLoading ? (
          <div className="content-loader">
            <Loader />
          </div>
        ) : null}
      </div>
      <div className="text mt-2">
        <p className="m-0 h6">{_content?.title}</p>
        <p className="m-0">{_content?.text}</p>
        {content.user ? (
          <p>
            <i>
              {t("content_card.created_by")}{" "}
              <Link to={"/channel/" + (content.user as iUser)?._id}>
                {(content.user as iUser)?.name ??
                  (content.user as iUser)?.email}
              </Link>
            </i>
          </p>
        ) : null}
      </div>
    </div>
  );
}

interface Props {
  className?: string;
  editors: Array<string | iUser>;
}

export default function ContentDrop({ className, editors }: Props) {
  const COLUMN_SIZE_PX = 340;

  const { id } = useParams();
  const [isLocaleLoading, setIsLocaleLoading] = useState(false);
  const [formattedContents, setFormattedContents] = useState<
    Array<Array<iContent>>
  >([]);

  const handleResize = (data: Array<iContent>) => {
    const resetFormattedData = () => {
      const formattedData = getFormattedData(data);
      setFormattedContents((formattedContents) => {
        if (formattedContents.length === formattedData.length)
          return formattedContents;
        return formattedData;
      });
    };
    const resetFormattedDataDebounced = debounce(resetFormattedData, 250);
    window.addEventListener("resize", () => {
      resetFormattedDataDebounced();
    });
  };

  const handleScroll = (
    page: number,
    contents: Array<iContent>,
    end: boolean
  ) => {
    if (end) return;
    window.addEventListener("scroll", function _handler() {
      const height =
        window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientWidth;

      const bottomPos =
        document.getElementById("content-drop")?.getBoundingClientRect()
          .bottom ?? 0;

      if (bottomPos < height) {
        loadContents(page + 1, contents, false);
        this.removeEventListener("scroll", _handler);
      }
    });
  };

  const loadContents = async (
    myPage = 1,
    contents: Array<iContent> = [],
    override = true
  ) => {
    if (!id) return;
    setIsLocaleLoading(true);

    try {
      const data = await getContentsOfBacheca(id, myPage);

      let myData: Array<iContent> = [];

      if (override) myData = data;
      else myData = [...contents, ...data];

      const formattedData = getFormattedData(myData);
      setFormattedContents(formattedData);

      handleResize(myData);
      handleScroll(myPage, myData, data.length === 0);
    } catch (error) {
      console.log(error);
    }

    setIsLocaleLoading(false);
  };

  const getFormattedData = (data: Array<iContent>): Array<Array<iContent>> => {
    const width =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth;

    const columns = Math.floor(width / COLUMN_SIZE_PX);

    const fData: Array<Array<iContent>> = [];

    let col = 0;
    data.forEach((content) => {
      if (!fData[col]) fData[col] = [];

      fData[col].push(content);
      col++;

      if (col >= columns) {
        col = 0;
      }
    });

    return fData;
  };

  useEffect(() => {
    loadContents();
    // eslint-disable-next-line
  }, [id]);

  return (
    <section id="content-drop" className={`content-drop ${className}`}>
      <div className="d-flex">
        {formattedContents.map((col, key) => {
          return (
            <div key={"content_col_" + key} className="column">
              {col.map((content) => {
                return (
                  <Content
                    key={"content_preview_" + content._id}
                    editors={editors}
                    content={content}
                    onUpdate={loadContents}
                  />
                );
              })}
            </div>
          );
        })}
      </div>
      <div className="d-flex justify-content-center mt-5">
        {isLocaleLoading ? <Loader /> : null}
      </div>
    </section>
  );
}
