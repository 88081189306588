import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  AVATAR_DEFAULT,
  COVER_DEFAULT,
  getUserProfile,
  iUser,
} from "../services/auth";
import { getBachecasOfChannel, iBacheca } from "../services/bacheca";
import { selectUser } from "../store/userSlice";
import BachecaList from "../components/generic/BachecaList";
import { useTranslation } from "react-i18next";
import Loader from "../components/Loader";
import Button from "../components/dom/Button";

export default function PublicProfile() {
  const user = useSelector(selectUser);
  const navigate = useNavigate();
  const [isMoreButtonEnabled, setIsMoreButtonEnabled] = useState(true);
  const { t } = useTranslation();

  const [profile, setProfile] = useState<iUser>();
  const { id } = useParams();

  const [isLocaleLoading, setIsLocaleLoading] = useState(false);

  const [bachecas, setBachecas] = useState<Array<iBacheca>>([]);
  const [, setPage] = useState(1);

  const loadBachecas = async (myPage = 1, override = true) => {
    if (!id) return navigate("");
    setIsLocaleLoading(true);

    try {
      const data = await getBachecasOfChannel(id, myPage);

      if (override) setBachecas(data);
      else setBachecas([...bachecas, ...data]);

      if (data.length === 0) setIsMoreButtonEnabled(false);
    } catch (error) {
      console.log(error);
    }

    setIsLocaleLoading(false);
  };

  const viewMoreClick = () => {
    setPage((page) => {
      loadBachecas(page + 1, false);
      return page + 1;
    });
  };

  const _initProfile = async () => {
    if (!id) return navigate("");

    setIsLocaleLoading(true);
    try {
      if (id === user._id) {
        setProfile(user);
      } else {
        const data = await getUserProfile(id);
        setProfile(data);
      }
    } catch (error) {
      console.log(error);
    }

    setIsLocaleLoading(false);
  };

  useEffect(() => {
    _initProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, user]);

  useEffect(() => {
    loadBachecas();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <main className="channel-page container">
      <div
        style={{
          backgroundImage: `url(${profile?.coverImage ?? COVER_DEFAULT})`,
          backgroundPosition: "center center",
          backgroundSize: "cover",
        }}
        className="cover-image"
      ></div>
      <div className="d-flex align-items-center gap-4">
        <div
          style={{
            backgroundImage: `url(${profile?.profileImage ?? AVATAR_DEFAULT})`,
            backgroundPosition: "center center",
            backgroundSize: "cover",
          }}
          className="profile-image"
        ></div>
        <div>
          <span className="h3 mb-4">{profile?.name}</span>
          <p>{profile?.bio}</p>
          <p>
            <a href={"tel:" + profile?.phone}>{profile?.phone}</a>
            {" / "}
            <a
              target="_blank"
              rel="noreferrer"
              href={
                profile?.website?.includes("http")
                  ? profile?.website
                  : "https://" + profile?.website
              }
            >
              {profile?.website}
            </a>
          </p>
        </div>
      </div>
      <br />
      {user?._id === profile?._id ? (
        <Button
          className="my-1"
          onClick={() => {
            navigate("/bacheca/create");
          }}
          text={t("board.create")}
        />
      ) : (
        ""
      )}
      <p className="mt-5 mb-0 h4">{t("channel.bacheca_list")}</p>
      <div className="hr mt-0"></div>
      <BachecaList className="" bachecas={bachecas} onUpdate={loadBachecas} />
      {isMoreButtonEnabled ? (
        <div className="mt-5 see-all-btn d-flex justify-content-center">
          {!isLocaleLoading ? (
            <span onClick={viewMoreClick} className="cursor-pointer">
              {t("channel.load_more")}
            </span>
          ) : (
            <Loader scale={0.8} />
          )}
        </div>
      ) : null}
    </main>
  );
}
