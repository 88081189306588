import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { login, register } from "../services/auth";
import { useAppDispatch } from "../services/redux";
import { setUser } from "../store/userSlice";
import { formToObject } from "../utils/generic";
import Button from "./dom/Button";
import Checkbox from "./dom/Checkbox";

interface Props {
  forgotPasswordClick: Function;
}

export default function Auth({ forgotPasswordClick }: Props) {
  const [authToggle, setAuthToggle] = useState(true);
  const [error, setError] = useState("");
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [isLocaleLoading, setIsLocaleLoading] = useState(false);
  const [isRegistrationMessage, setIsRegistrationMessage] = useState(false);

  const loginForm = async (e: any) => {
    setIsLocaleLoading(true);
    setError("");

    e.preventDefault();

    const payload = formToObject(e.target);

    try {
      const user = await login(payload);
      dispatch(setUser(user));
    } catch (error: any) {
      setError(error?.response?.data?.message);
    }
    setIsLocaleLoading(false);
  };

  const registerForm = async (e: any) => {
    setIsLocaleLoading(true);

    setError("");

    e.preventDefault();

    const payload = formToObject(e.target);

    try {
      const user = await register(payload);
      dispatch(setUser(user));
      setIsRegistrationMessage(true);
    } catch (error: any) {
      setError(error?.response?.data?.message);
    }
    setIsLocaleLoading(false);
  };

  if (isRegistrationMessage)
    return (
      <section className="auth-box">
        <p className="mt-5 text-center">
          {t("auth.registered_check_mail_text")}
        </p>
        <div className="d-flex justify-content-center">
          <Button
            text={t("auth.login")}
            onClick={() => {
              setIsRegistrationMessage(false);
              setAuthToggle(true);
            }}
          />
        </div>
      </section>
    );

  return (
    <section className="auth-box">
      <div className="d-flex justify-content-center mb-5">
        <div className="auth-toggle">
          <div
            onClick={() => {
              setError("");
              setAuthToggle(true);
            }}
            className={`${authToggle ? "selected" : ""}`}
          >
            {t("auth.login")}
          </div>
          <div
            onClick={() => {
              setError("");
              setAuthToggle(false);
            }}
            className={`${authToggle ? "" : "selected"}`}
          >
            {t("auth.register")}
          </div>
        </div>
      </div>

      {authToggle ? (
        <>
          <form onSubmit={loginForm}>
            <label htmlFor="email">Email</label>
            <input
              disabled={isLocaleLoading}
              required
              type="email"
              name="email"
              id="email"
            />
            <label htmlFor="password">Password</label>
            <input
              disabled={isLocaleLoading}
              required
              type="password"
              name="password"
              id="password"
            />
            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex align-items-center ">
                <Checkbox id="remember" className="m-2 cursor-pointer" />
                <label
                  className="m-2 cursor-pointer text-nowrap"
                  htmlFor="remember"
                >
                  {t("auth.remember_logged")}
                </label>
              </div>
              <u>
                <span
                  onClick={() => forgotPasswordClick()}
                  className="m-2 cursor-pointer text-nowrap"
                >
                  {t("auth.forgot_password")}
                </span>
              </u>
            </div>
            <Button
              className="mt-5"
              text={t("auth.login")}
              loading={isLocaleLoading}
              disabled={isLocaleLoading}
              type="submit"
            />
          </form>
        </>
      ) : (
        <>
          <form onSubmit={registerForm}>
            <label htmlFor="email">Email</label>
            <input
              disabled={isLocaleLoading}
              required
              type="email"
              name="email"
              id="email"
            />
            <label htmlFor="password">Password</label>
            <input
              disabled={isLocaleLoading}
              required
              type="password"
              name="password"
              id="password"
            />
            <div className="d-flex align-items-center">
              <Checkbox
                className="m-2 cursor-pointer"
                id="newsletter_email"
                name="newsletter_email"
              />
              <label className="m-2 cursor-pointer" htmlFor="newsletter_email">
                {t("auth.register_newsletter")}
              </label>
            </div>
            <Button
              className="mt-5"
              text={t("auth.register")}
              loading={isLocaleLoading}
              disabled={isLocaleLoading}
              type="submit"
            />
            <span className="mt-3">
              {t("auth.tems_privacy_text")}{" "}
              <Link to={"/terms-privacy"}>{t("auth.terms_privacy")}</Link>
            </span>
          </form>
        </>
      )}
      <p className="text-center mt-3">
        <i>{error}</i>
      </p>
    </section>
  );
}
