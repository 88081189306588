import { iLike } from "./../services/like";
import { iUser } from "./../services/auth";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../services/redux";

interface UserState {
  info: any;
  notificationNumber: number;
  likes: Array<iLike>;
}

const initialState: UserState = {
  info: {},
  notificationNumber: 0,
  likes: [],
};

export const userSlice = createSlice({
  name: "user",
  initialState: initialState,
  reducers: {
    setUser: (state, action: PayloadAction<any>) => {
      state.info = action.payload;
    },
    setNotificationNumber: (state, action: PayloadAction<any>) => {
      state.notificationNumber = action.payload;
    },
    setLikes: (state, action: PayloadAction<any>) => {
      state.likes = action.payload;
    },
  },
});

export const { setUser } = userSlice.actions;
export const selectUser = (state: RootState) => state.user.info as iUser;

export const { setLikes } = userSlice.actions;
export const selectLikes = (state: RootState) => state.user.likes;

export const { setNotificationNumber } = userSlice.actions;
export const selectNotificationNumber = (state: RootState) =>
  state.user.notificationNumber;

export default userSlice.reducer;
