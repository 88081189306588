import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import Comments from "../components/Comments";
import Button from "../components/dom/Button";
import Likes from "../components/generic/Likes";
import Loader from "../components/Loader";
import {
  AVATAR_DEFAULT,
  getUserProfile,
  isLoggedIn,
  iUser,
} from "../services/auth";
import {
  addLikeContent,
  getContent,
  iContent,
  removeLikeContent,
} from "../services/content";
import { addLocalLike, removeLocalLike, userLikes } from "../services/like";

export default function ContentDetail() {
  const [content, setContent] = useState<iContent>();
  const [creator, setCreator] = useState<iUser>();
  const { id } = useParams();
  const [selectedImage, setSelectedImage] = useState(0);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [, setIsLocaleLoading] = useState(false);

  const _loadContent = async () => {
    if (!id) return;

    setIsLocaleLoading(true);

    try {
      const data = await getContent(id);
      setContent(data);

      const datacreator = await getUserProfile(String(data.user));
      setCreator(datacreator);
    } catch (error) {
      console.log(error);
    }
    setIsLocaleLoading(false);
  };

  useEffect(() => {
    _loadContent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addLike = async () => {
    if (!content) return;
    if (!isLoggedIn()) return;
    setIsLocaleLoading(true);

    try {
      await addLikeContent(content._id);
      setContent({ ...content, likeAmount: content.likeAmount + 1 });

      addLocalLike(content._id);
    } catch (error) {
      console.log(error);
    }
    setIsLocaleLoading(false);
  };

  const removeLike = async () => {
    if (!content) return;
    if (!isLoggedIn()) return;
    setIsLocaleLoading(true);

    try {
      await removeLikeContent(content._id);
      setContent({ ...content, likeAmount: content.likeAmount - 1 });

      removeLocalLike(content._id);
    } catch (error) {
      console.log(error);
    }
    setIsLocaleLoading(false);
  };

  if (!content)
    return (
      <div className="d-flex justify-content-center align-items-center">
        <Loader />
      </div>
    );

  return (
    <main className="content-detail">
      <div className="container">
        <Button
          text={"< " + t("content.return_to_bacheca")}
          onClick={() => navigate("/bacheca/" + content.bacheca)}
          small
          className="mb-4"
        />
        <div className="row">
          <div className="col-md mb-5">
            <div className="image-carousel">
              <div className="images-preview">
                {content.images.map((i, key) => {
                  return (
                    <div
                      key={"image_preview_" + key}
                      style={{
                        backgroundImage: `url(${i})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center center",
                      }}
                      onClick={() => setSelectedImage(key)}
                      className={`image-small cursor-pointer d-none d-md-block ${
                        selectedImage === key ? "selected" : ""
                      }`}
                    ></div>
                  );
                })}
              </div>
              <div
                style={{
                  backgroundImage: `url(${content.images[selectedImage]})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center center",
                }}
                className="image"
              >
                <div className="navigator">
                  <div
                    onClick={() => {
                      setSelectedImage(selectedImage - 1);
                    }}
                    className={`cursor-pointer left ${
                      selectedImage <= 0 ? "d-none" : ""
                    }`}
                  >
                    {"<"}
                  </div>
                  <div
                    onClick={() => {
                      setSelectedImage(selectedImage + 1);
                    }}
                    className={`cursor-pointer right ml-auto ${
                      selectedImage >= content.images.length - 1 ? "d-none" : ""
                    }`}
                  >
                    {">"}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md">
            <div className="info">
              <p className="h4">{content.title}</p>
              {content.user ? (
                <div className="creator my-4">
                  <div
                    style={{
                      backgroundImage: `url(${
                        creator?.profileImage ?? AVATAR_DEFAULT
                      })`,
                      backgroundSize: "cover",
                      backgroundPosition: "center center",
                    }}
                    className="image"
                  ></div>
                  <div>
                    <p className="m-0 text-muted">
                      <i>{t("content.created_by")}</i>
                    </p>

                    <p
                      className="h6 cursor-pointer m-0 mb-3"
                      onClick={() => navigate("/channel/" + creator?._id)}
                    >
                      {creator?.name}
                    </p>
                  </div>
                </div>
              ) : null}

              <p>{content.text}</p>
              <Likes
                number={content.likeAmount}
                active={userLikes(content._id)}
                onClick={() => {
                  if (userLikes(content._id)) removeLike();
                  else addLike();
                }}
              />
            </div>
          </div>
        </div>
        <div className="w100 mt-5">
          <Comments content_id={content._id} />
        </div>
      </div>
    </main>
  );
}
