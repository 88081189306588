import {
  BACHECA_PAY_STATUS,
  iBacheca,
  patchBacheca,
} from "../../services/bacheca";
import flowerSvg from "../../assets/svg/flower.svg";
import { datetimeToString } from "../../utils/generic";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import Loader from "../Loader";
import DropdownMenu from "../dom/DropdownMenu";
import { useSelector } from "react-redux";
import { selectUser } from "../../store/userSlice";
import { useTranslation } from "react-i18next";
import Button from "../dom/Button";
import { useAppDispatch } from "../../services/redux";
import {
  setIsBachecaDeleteModalOpen,
  setIsBachecaSettingsModalOpen,
  setIsBachecaTransferModalOpen,
  setIsCheckoutModalOpen,
} from "../../store/appSlice";

const DEFAULT_IMAGE =
  "https://thegraphicsfairy.com/wp-content/uploads/2021/03/Antique-Family-Pic-GraphicsFairy.jpg";

interface Props {
  bacheca: iBacheca;
  onUpdate?: Function;
}

export default function BachecaCard({ bacheca, onUpdate }: Props) {
  const [isLocaleLoading, setIsLocaleLoading] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const user = useSelector(selectUser);
  const dispatch = useAppDispatch();

  const togglePublishButton = async (payload: any) => {
    setIsLocaleLoading(true);

    try {
      await patchBacheca(payload);
    } catch (error) {
      console.log(error);
    }

    setIsLocaleLoading(false);
    onUpdate?.();
  };

  const dropdownMenuOptions = [
    { text: t("board_card.edit"), action: () => navigate("/bacheca/edit/" + bacheca._id) },
    {
      text: t("board_card.access"),
      hidden: user._id !== bacheca.user,
      action: () => {
        dispatch(
          setIsBachecaSettingsModalOpen({
            bacheca: bacheca,
            callback: () => onUpdate?.(),
          })
        );
      },
    },
    {
      text: t("board_card.hide"),
      hidden: bacheca.payStatus === BACHECA_PAY_STATUS.TO_PAY || bacheca.hidden,

      action: () =>
        togglePublishButton({ hidden: "on", bacheca_id: bacheca._id }),
    },
    {
      text: t("board_card.transfer"),
      hidden: user._id !== bacheca.user,
      action: () =>
        dispatch(
          setIsBachecaTransferModalOpen({
            bacheca: bacheca,
            callback: () => onUpdate?.(),
          })
        ),
    },
    {
      text: t("board_card.delete"),
      hidden: user._id !== bacheca.user,
      red: true,
      action: () =>
        dispatch(
          setIsBachecaDeleteModalOpen({
            bacheca: bacheca,
            callback: () => onUpdate?.(),
          })
        ),
    },
  ];

  const isOwner = user._id === bacheca.user;
  const isEditor = bacheca.editors.includes(user._id);

  return (
    <div className="bacheca-card-container">
      {bacheca.payStatus === BACHECA_PAY_STATUS.TO_PAY ? (
        <div className="metaslot error">
          <span>{t("board_card.bacheca_not_paid")}</span>
          <Button
            onClick={() =>
              dispatch(
                setIsCheckoutModalOpen({
                  bacheca: bacheca,
                  callback: () => onUpdate?.(),
                })
              )
            }
            small
            text={t("board_card.pay")}
          />
        </div>
      ) : null}
      {bacheca.payStatus === BACHECA_PAY_STATUS.PAID && bacheca.hidden ? (
        <div className="metaslot info">
          <span>{t("board_card.bacheca_not_published")}</span>
          <Button
            onClick={() =>
              togglePublishButton({ hidden: "off", bacheca_id: bacheca._id })
            }
            small
            text={t("board_card.publish")}
          />
        </div>
      ) : null}
      {isLocaleLoading ? (
        <div className="card-loader">
          <Loader />
        </div>
      ) : null}
      <div
        onClick={() => navigate("/bacheca/" + bacheca._id)}
        className="bacheca-card"
      >
        {isEditor || isOwner ? (
          <>
            <DropdownMenu
              position="left"
              className="options d-md-none"
              options={dropdownMenuOptions}
            />
            <DropdownMenu
              position="down"
              className="options d-none d-md-flex"
              options={dropdownMenuOptions}
            />
          </>
        ) : null}

        <div
          className="img w100 h100"
          style={{
            backgroundImage: `url(${bacheca.profileImage ?? DEFAULT_IMAGE})`,
            backgroundPosition: "center center",
            backgroundSize: "cover",
          }}
        ></div>

        <div className="info">
          <p className="h4 m-0 text-capitalize">{bacheca.name}</p>
          <p className="m-0 birth-death">
            <i className="d-none d-xl-block">
              {datetimeToString(bacheca.birthTime)} {bacheca.birthCity} -{" "}
              {datetimeToString(bacheca.deathTime)} {bacheca.deathCity}
            </i>
            <i className="d-xl-none">
              {datetimeToString(bacheca.birthTime, true)} {bacheca.birthCity} -{" "}
              {datetimeToString(bacheca.deathTime, true)} {bacheca.deathCity}
            </i>
          </p>
          <p className="bio">
            {bacheca.bio.length > 200
              ? bacheca.bio.substring(0, 200) + "..."
              : bacheca.bio}
          </p>
          <div className="stats">
            <p className="slot m-0 text-muted">
              {bacheca.likeAmount}
              <img src={flowerSvg} alt="flower icon" />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
