import axios from "axios";
import { iUser } from "./auth";
import { APPROVAL_RULE, iBacheca } from "./bacheca";
import { loadUserLikes } from "./like";

export interface iContent {
  _id: string;
  title: string;
  text: string;
  user?: iUser | string;
  bacheca: iBacheca | string;
  images: Array<string>;
  approvedStatus: APPROVAL_RULE;
  createdAt: any;
  updatedAt: any;
  hidden: boolean;
  likeAmount: number;
}

export async function getContentsOfBacheca(
  bacheca_id: string,
  page: number = 1
): Promise<Array<iContent>> {
  const response = await axios.get(
    `/bacheca/${bacheca_id}/contents?page=${page}`
  );

  await loadUserLikes({
    content_ids: (response.data as Array<iContent>).map((e) => e._id),
  });

  return response.data;
}

export async function getContent(id: string): Promise<iContent> {
  const response = await axios.get(`/content/${id}`);

  await loadUserLikes({
    content_ids: [(response.data as iContent)._id],
  });

  return response.data;
}

export async function createContent(payload: any): Promise<iContent> {
  const response = await axios.post("/content", payload);
  return response.data;
}

export async function patchContent(payload: any): Promise<iContent> {
  const response = await axios.patch("/content", payload);
  return response.data;
}

export async function deleteContent(id: string): Promise<any> {
  const response = await axios.delete("/content", { data: { content_id: id } });
  return response.data;
}

export async function addLikeContent(id: string): Promise<any> {
  const response = await axios.post("/like", {
    content_id: id,
  });
  return response.data;
}

export async function removeLikeContent(id: string): Promise<any> {
  const response = await axios.delete("/like", {
    data: { content_id: id },
  });
  return response.data;
}

export async function setContentApprovalStatus(
  payload: any
): Promise<iContent> {
  const response = await axios.post("/content/approval", payload);
  return response.data;
}
