import { Route, Routes, useLocation } from "react-router-dom";
import BachecaDetail from "./pages/BachecaDetail";
import ContentDetail from "./pages/ContentDetail";
import BachecaForm from "./pages/edit/BachecaForm";
import Home from "./pages/Home";
import VerifyEmailToken from "./components/VerifyEmailToken";
import Search from "./pages/Search";
import PublicProfile from "./pages/PublicProfile";
import ContentForm from "./pages/edit/ContentForm";
import Promo from "./pages/Promo";
import TermsAndPrivacy from "./pages/TermsAndPrivacy";
import { useEffect } from "react";
import Partners from "./pages/Partners";
import PartnerList from "./pages/PartnerList";

export default function Router() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/profile" element={<Home />} />
      <Route path="/partner-list" element={<PartnerList />} />
      <Route path="/partners" element={<Partners />} />
      <Route path="/password-recovery" element={<Home />} />
      <Route path="/verify-email" element={<VerifyEmailToken />} />
      <Route path="/search/:query" element={<Search />} />
      <Route path="/search/" element={<Search />} />
      <Route path="/channel/:id" element={<PublicProfile />} />
      <Route path="/bacheca/create" element={<BachecaForm />} />
      <Route path="/bacheca/edit/:id" element={<BachecaForm />} />
      <Route path="/bacheca/:id" element={<BachecaDetail />} />
      <Route
        path="/bacheca/:bachecaId/content/create"
        element={<ContentForm />}
      />
      <Route
        path="/bacheca/:bachecaId/content/edit/:contentId"
        element={<ContentForm />}
      />
      <Route path="/content/:id" element={<ContentDetail />} />
      <Route path="/terms-privacy" element={<TermsAndPrivacy />} />

      {/* admin routes */}
      <Route path="/promo" element={<Promo />} />
    </Routes>
  );
}

export function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "auto" });
  }, [pathname]);
  return <></>;
}
