import axios from "axios";

export interface iPaymentPreview {
  originalPrice: number;
  price: number;
}

export async function createPaymentIntent(
  bacheca_id: string,
  billing_details: object,
  promo_code?: string
) {
  console.log(billing_details)
  const response = await axios.post("/bacheca/payment/intent", {
    bacheca_id,
    promo_code,
    billing_details,
  });
  return response.data;
}

export async function createFreeIntent(
  bacheca_id: string,
  promo_code?: string
) {
  const response = await axios.post("/bacheca/payment/free", {
    bacheca_id,
    promo_code,
  });
  return response.data;
}

export async function getPaymentPreview(
  bacheca_id: string,
  promo_code?: string
): Promise<iPaymentPreview> {
  const response = await axios.post("/bacheca/payment/preview", {
    bacheca_id,
    promo_code,
  });
  return response.data;
}
