import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { useNavigate } from "react-router-dom";
import {
  getNotifications,
  iNotification,
  seeAllNotifications,
  seeNotification,
} from "../../services/notification";
import Button from "../dom/Button";
import Loader from "../Loader";

interface Props {
  onNotificationClick: Function;
  onSeeAllClick: Function;
}

export default function NotificationBox({
  onNotificationClick,
  onSeeAllClick,
}: Props) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [notifications, setNotifications] = useState<Array<iNotification>>([]);
  const [isLocaleLoading, setIsLocaleLoading] = useState(false);
  const [, setPage] = useState(1);
  const [isMoreButtonEnabled, setIsMoreButtonEnabled] = useState(true);

  const loadNotifications = async (myPage = 1) => {
    setIsLocaleLoading(true);
    try {
      const data = await getNotifications(myPage);
      setNotifications([...notifications, ...data]);

      if (data.length === 0) setIsMoreButtonEnabled(false);
    } catch (error) {
      console.log(error);
    }
    setIsLocaleLoading(false);
  };

  useEffect(() => {
    loadNotifications();
    // eslint-disable-next-line
  }, []);

  const seeNotificationClick = async (notification: iNotification) => {
    const wasSeen = notification.seen;

    if (!notification.seen)
      try {
        await seeNotification(notification._id);

        const editedNotifications = notifications?.map((n) => {
          if (n._id === notification._id) n.seen = true;
          return n;
        });

        setNotifications(editedNotifications);
      } catch (error) {
        console.log(error);
      }

    onNotificationClick(wasSeen);
    const uri = String(notification.link).replace(
      String(process.env.REACT_APP_FRONTEND_URI),
      ""
    );
    navigate(uri ?? "");
  };

  const seeAllClick = async () => {
    try {
      await seeAllNotifications();

      const editedNotifications = notifications?.map((n) => {
        n.seen = true;
        return n;
      });

      setNotifications(editedNotifications);
      onSeeAllClick();
    } catch (error) {
      console.log(error);
    }
  };

  const viewMoreClick = () => {
    setPage((page) => {
      loadNotifications(page + 1);
      return page + 1;
    });
  };

  return (
    <section className="notification-box">
      {notifications?.length !== 0 ? (
        <div className="see-all-btn d-flex justify-content-end">
          <Button
            onClick={() => seeAllClick()}
            small
            text={t("notifications.see_all")}
          />
        </div>
      ) : null}
      {notifications?.map((n, index) => (
        <div
          onClick={() => {
            seeNotificationClick(n);
          }}
          key={"notification_" + index}
          className={`notification d-flex ${!n.seen ? "new" : ""}`}
        >
          <div className="col-2">
            <div className="d-flex align-items-center justify-content-center h100 w100">
              <div className="icon">{n.notificationType.substring(0, 1)}</div>
            </div>
          </div>
          <div className="col-10">
            <p className="title">{n.title}</p>
            <p className="text">{n.text}</p>
            <p className="text-muted mt-2">{n.createdAt}</p>
          </div>
        </div>
      ))}
      {notifications?.length === 0 ? (
        <div className="text-center mt-3">{t("notifications.no_results")}</div>
      ) : null}
      {isMoreButtonEnabled ? (
        <div className="mt-3 see-all-btn d-flex justify-content-center">
          {!isLocaleLoading ? (
            <span onClick={viewMoreClick} className="cursor-pointer">
              {t("notifications.load_more")}
            </span>
          ) : (
            <Loader scale={0.5} />
          )}
        </div>
      ) : null}
    </section>
  );
}
