interface Props {
  steps: Array<string>;
  currentStep: number;
  onChange: Function;
  className?: string;
}

export default function Stepper({
  steps,
  currentStep,
  onChange,
  className,
}: Props) {
  return (
    <div className={`stepper ${className}`}>
      {steps.map((s, key) => {
        return (
          <>
            <div
              className={`step cursor-pointer 
            ${currentStep >= key ? "active" : ""} 
            ${currentStep > key ? "passed" : ""}`}
              onClick={() => {
                onChange(key);
              }}
            >
              {currentStep <= key ? (
                <div className="step-dot">{key + 1}</div>
              ) : (
                <div className="step-dot">v</div>
              )}
              <div className="step-text h6">{s}</div>
            </div>
            <div className="step-divider"></div>
          </>
        );
      })}
    </div>
  );
}
