import { useEffect, useState } from "react";
import { getAllPartners, iUser } from "../services/auth";
import Loader from "../components/Loader";
import { useTranslation } from "react-i18next";

export default function PartnerList() {
  const [isLoading, setIsLoading] = useState(false);
  const [partners, setPartners] = useState<iUser[]>([]);
  const { t } = useTranslation();

  const [filter, setFilter] = useState("");

  const load = async () => {
    setIsLoading(true);
    try {
      const data = await getAllPartners();
      setPartners(data);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    load();
  }, []);

  if (isLoading) return <Loader />;

  return (
    <main>
      <div className="container">
        <div className="content">
          <h1>{t("partners.title")}</h1>

          <input
            placeholder="Search"
            className="w100"
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
          />

          <br />
          <br />

          {partners
            .filter((e) => JSON.stringify(e).includes(filter))
            .map((partner, key) => {
              return (
                <div
                  className="border rounded p-3 d-flex flex-column"
                  key={key}
                >
                  <div className="d-flex flex-column flex-md-row justify-content-between">
                    <strong>{partner.name}</strong>
                    <div>
                      <a target="_blank" href={partner.website}>
                        {partner.website}
                      </a>{" "}
                      / <a href={"tel:" + partner.phone}>{partner.phone}</a>
                    </div>
                  </div>
                  <p>
                    <small>
                      {partner.location?.address}, {partner.location?.region},{" "}
                      {partner.location?.province}, {partner.location?.city},{" "}
                      {partner.location?.country}
                    </small>
                  </p>
                  <p>{partner.bio}</p>
                </div>
              );
            })}
        </div>
      </div>
    </main>
  );
}
