import { range } from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import DatePicker from "react-datepicker";
import Button from "./Button";
import Select from "./Select";

interface Props {
  name: string;
  defaultValue?: any;
}

export default function CustomDatePicker(props: Props) {
  const { t } = useTranslation();

  const [startDate, setStartDate] = useState<Date | undefined>();
  const [rawDate, setRawDate] = useState<string | undefined>();

  const years = range(new Date().getFullYear(), 1800, -1);
  const months = [
    t("january"),
    t("february"),
    t("march"),
    t("april"),
    t("may"),
    t("june"),
    t("july"),
    t("august"),
    t("september"),
    t("october"),
    t("november"),
    t("december"),
  ];

  useEffect(() => {
    if (props.defaultValue) {
      const date = new Date(props.defaultValue);
      setStartDate(date);
      setRawDate(date.toISOString());
    }
  }, [props]);

  const change = (date: Date) => {
    setStartDate(date);
    setRawDate(date.toISOString());
  };

  return (
    <>
      <input
        hidden
        name={props.name}
        type="text"
        value={rawDate || ""}
        readOnly
      />
      <DatePicker
        renderCustomHeader={({
          changeYear,
          changeMonth,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }) => (
          <div
            style={{
              margin: 10,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button
              small
              type="button"
              text="<"
              onClick={decreaseMonth}
              disabled={prevMonthButtonDisabled}
            />

            <Select
              small
              onChange={(e: any) => changeYear(Number(e.target.value))}
              options={years.map((y) => {
                return { value: y, text: y };
              })}
              defaultValue={new Date().getFullYear()}
            />

            <Select
              small
              defaultValue={months[new Date().getMonth()]}
              onChange={(e: any) => changeMonth(months.indexOf(e.target.value))}
              options={months.map((m) => {
                return { value: m, text: m };
              })}
            />

            <Button
              small
              type="button"
              text=">"
              onClick={increaseMonth}
              disabled={nextMonthButtonDisabled}
            />
          </div>
        )}
        selected={startDate}
        onChange={change}
      />
    </>
  );
}
