import { ModalPage, ModalPageProps } from "../components/generic/Modal";
import store from "../services/redux";
import { setModal } from "../store/appSlice";

export function openModal(props: ModalPageProps) {
  const modal = (
    <ModalPage
      title={props.title}
      actions={props.actions}
      content={props.content}
      small={props.small}
      style={props.style}
      className={props.className}
    />
  );

  store.dispatch(setModal(modal));
}

export function closeModal() {
  store.dispatch(setModal(null));
}
