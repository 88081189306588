import { iContent } from "./../services/content";
import { iComment } from "./../services/comment";
import { iBacheca } from "./../services/bacheca";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../services/redux";
import { ReactNode } from "react";

interface AppState {
  isLoading: boolean;
  isBachecaDeleteModalOpen:
    | undefined
    | { bacheca: iBacheca; callback: Function };
  isCommentDeleteModalOpen:
    | undefined
    | { comment: iComment; callback: Function };
  isContentDeleteModalOpen:
    | undefined
    | { content: iContent; callback: Function };
  isBachecaSettingsModalOpen:
    | undefined
    | { bacheca: iBacheca; callback: Function };
  isBachecaTransferModalOpen:
    | undefined
    | { bacheca: iBacheca; callback: Function };
  isCheckoutModalOpen: undefined | { bacheca: iBacheca; callback: Function };
  modal: React.ReactNode | null;
}

const initialState: AppState = {
  isLoading: false,
  isBachecaDeleteModalOpen: undefined,
  isBachecaSettingsModalOpen: undefined,
  isBachecaTransferModalOpen: undefined,
  isCommentDeleteModalOpen: undefined,
  isContentDeleteModalOpen: undefined,
  isCheckoutModalOpen: undefined,
  modal: null,
};

export const appSlice = createSlice({
  name: "app",
  initialState: initialState,
  reducers: {
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setIsBachecaDeleteModalOpen: (
      state,
      action: PayloadAction<
        undefined | { bacheca: iBacheca; callback: Function }
      >
    ) => {
      state.isBachecaDeleteModalOpen = action.payload;
    },
    setIsCommentDeleteModalOpen: (
      state,
      action: PayloadAction<
        undefined | { comment: iComment; callback: Function }
      >
    ) => {
      state.isCommentDeleteModalOpen = action.payload;
    },
    setIsContentDeleteModalOpen: (
      state,
      action: PayloadAction<
        undefined | { content: iContent; callback: Function }
      >
    ) => {
      state.isContentDeleteModalOpen = action.payload;
    },
    setIsBachecaSettingsModalOpen: (
      state,
      action: PayloadAction<
        undefined | { bacheca: iBacheca; callback: Function }
      >
    ) => {
      state.isBachecaSettingsModalOpen = action.payload;
    },
    setIsBachecaTransferModalOpen: (
      state,
      action: PayloadAction<
        undefined | { bacheca: iBacheca; callback: Function }
      >
    ) => {
      state.isBachecaTransferModalOpen = action.payload;
    },
    setIsCheckoutModalOpen: (
      state,
      action: PayloadAction<
        undefined | { bacheca: iBacheca; callback: Function }
      >
    ) => {
      state.isCheckoutModalOpen = action.payload;
    },
    setModal: (state, action: PayloadAction<ReactNode>) => {
      state.modal = action.payload;
    },
  },
});

export const { setIsLoading } = appSlice.actions;
export const selectLoading = (state: RootState) => state.app.isLoading;

export const { setModal } = appSlice.actions;
export const selectModal = (state: RootState) => state.app.modal;

export const { setIsBachecaDeleteModalOpen } = appSlice.actions;
export const selectBachecaDeleteModalOpen = (state: RootState) =>
  state.app.isBachecaDeleteModalOpen;

export const { setIsCommentDeleteModalOpen } = appSlice.actions;
export const selectCommentDeleteModalOpen = (state: RootState) =>
  state.app.isCommentDeleteModalOpen;

export const { setIsContentDeleteModalOpen } = appSlice.actions;
export const selectContentDeleteModalOpen = (state: RootState) =>
  state.app.isContentDeleteModalOpen;

export const { setIsBachecaSettingsModalOpen } = appSlice.actions;
export const selectBachecaSettingsModalOpen = (state: RootState) =>
  state.app.isBachecaSettingsModalOpen;

export const { setIsBachecaTransferModalOpen } = appSlice.actions;
export const selectBachecaTransferModalOpen = (state: RootState) =>
  state.app.isBachecaTransferModalOpen;

export const { setIsCheckoutModalOpen } = appSlice.actions;
export const selectCheckoutModalOpen = (state: RootState) =>
  state.app.isCheckoutModalOpen;

export default appSlice.reducer;
