import { useSelector } from "react-redux";

import { ReactNode, useEffect } from "react";
import { closeModal } from "../../utils/modal";
import { selectModal } from "../../store/appSlice";

export interface ModalPageProps {
  title?: string;
  content: ReactNode;
  actions?: ReactNode[];
  small?: boolean;
  className?: string;
  style?: any;
}

export function ModalPage({
  title,
  content,
  actions,
  small,
  className,
  style,
}: ModalPageProps) {
  return (
    <section
      style={style ?? {}}
      className={`w100 d-flex flex-column rounded overflow-hidden ${
        small ? "mw-500" : ""
      } ${className}`}
    >
      {title ? (
        <div className="w100 p-3 bg-black">
          <span className="display-6 text-white">{title}</span>
        </div>
      ) : null}
      <div className="modal-page-scroll overflow-auto bg-white">
        <div className="p-3">{content}</div>
        {actions ? (
          <div className="d-flex justify-content-end pb-3 px-3 gap-2">
            {actions.map((a, key) => {
              return <div key={"modal_action_" + key}>{a}</div>;
            })}
          </div>
        ) : null}
      </div>
    </section>
  );
}

export default function Modal() {
  const modal = useSelector(selectModal);

  if (!modal) return <></>;

  return (
    <div id="modal-container">
      <div className="modal-middle">
        <div onClick={(e) => e.stopPropagation()} className={`modal-content`}>
          <span
            style={{ fontWeight: "900" }}
            className="close-modal"
            onClick={() => {
              closeModal();
            }}
          >
            x
          </span>

          {modal}
        </div>
      </div>
    </div>
  );
}
