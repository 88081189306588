import Links from "../Links";

interface Props {
  onClick?: Function;
}

export default function MenuBox(props: Props) {
  return (
    <div onClick={() => props.onClick?.()} className="menu-box">
      <Links />
    </div>
  );
}
