import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AVATAR_DEFAULT, isLoggedIn } from "../services/auth";
import {
  createComment,
  getCommentsOfBacheca,
  getCommentsOfContent,
  iComment,
  likeComment,
  unlikeComment,
} from "../services/comment";
import { autoGrowTextArea, formToObject } from "../utils/generic";
import Button from "./dom/Button";
import Loader from "./Loader";
import { selectUser } from "../store/userSlice";
import { useSelector } from "react-redux";
import Likes from "./generic/Likes";
import DropdownMenu from "./dom/DropdownMenu";
import { addLocalLike, removeLocalLike, userLikes } from "../services/like";
import { setIsCommentDeleteModalOpen } from "../store/appSlice";
import { useAppDispatch } from "../services/redux";
import { useNavigate } from "react-router-dom";

interface CommentProps {
  comment: iComment;
  className?: string;
  editors?: Array<string>;
  onUpdate?: Function;
}

function Comment({ comment, className, editors, onUpdate }: CommentProps) {
  const miniCommentLenght = 300;
  const dispatch = useAppDispatch();
  const [isCommentOpen, setIsCommentOpen] = useState(false);
  const { t } = useTranslation();
  const [isLocaleLoading, setIsLocaleLoading] = useState(false);

  const [_comment, _setComment] = useState<iComment>(comment);

  const user = useSelector(selectUser);

  const canEdit = () => {
    if (editors?.includes(user._id)) return true;
    if (comment.user._id === user._id) return true;
    return false;
  };

  useEffect(() => {
    _setComment(comment);
    // eslint-disable-next-line
  }, []);

  const likeCommentButton = async () => {
    if (!isLoggedIn()) return;
    setIsLocaleLoading(true);
    try {
      await likeComment(comment._id);
      _setComment((c) => {
        c.likeAmount++;
        return c;
      });
      addLocalLike(comment._id);
    } catch (error) {
      console.log(error);
    }
    setIsLocaleLoading(false);
  };

  const unlikeCommentButton = async () => {
    if (!isLoggedIn()) return;
    setIsLocaleLoading(true);
    try {
      await unlikeComment(comment._id);
      _setComment((c) => {
        c.likeAmount--;
        return c;
      });
      removeLocalLike(comment._id);
    } catch (error) {
      console.log(error);
    }
    setIsLocaleLoading(false);
  };

  return (
    <div className={`comment position-relative ${className}`}>
      <div
        style={{
          backgroundImage: `url(${
            comment.user.profileImage ?? AVATAR_DEFAULT
          })`,
          backgroundPosition: "center center",
          backgroundSize: "cover",
        }}
        className="image"
      ></div>
      <div className="text w100">
        <p className="h6 m-0">{comment.user.name ?? comment.user.email}</p>

        {comment.text.length > miniCommentLenght ? (
          <>
            {isCommentOpen ? (
              <>
                {comment.text}{" "}
                <span
                  onClick={() => setIsCommentOpen(false)}
                  className="cursor-pointer"
                >
                  <strong>{t("read_less")}</strong>
                </span>
              </>
            ) : (
              <>
                {comment.text.substring(0, miniCommentLenght) + "..."}
                <span
                  onClick={() => setIsCommentOpen(true)}
                  className="cursor-pointer"
                >
                  <strong>{t("read_more")}</strong>
                </span>
              </>
            )}
          </>
        ) : (
          <>{comment.text}</>
        )}

        <Likes
          className="mt-2 comment-like"
          active={userLikes(_comment._id)}
          number={_comment.likeAmount}
          onClick={() => {
            if (isLocaleLoading) return;
            if (userLikes(_comment._id)) unlikeCommentButton();
            else likeCommentButton();
          }}
        />
      </div>
      {canEdit() ? (
        <DropdownMenu
          position="left"
          options={[
            {
              text: t("delete"),
              red: true,
              action: () =>
                dispatch(
                  setIsCommentDeleteModalOpen({
                    comment: comment,
                    callback: () => onUpdate?.(),
                  })
                ),
            },
          ]}
        />
      ) : null}
    </div>
  );
}

interface Props {
  content_id?: string;
  bacheca_id?: string;
  editors?: Array<string>;
}

export default function Comments({ content_id, bacheca_id, editors }: Props) {
  const [comments, setComments] = useState<Array<iComment>>([]);
  const [isLocaleLoading, setIsLocaleLoading] = useState(false);
  const { t } = useTranslation();
  const [isMoreButtonEnabled, setIsMoreButtonEnabled] = useState(true);
  const [, setPage] = useState(1);
  const [isExpanded, setIsExpanded] = useState(false);
  const user = useSelector(selectUser);
  const navigate = useNavigate();

  const _loadComments = async (myPage = 1, override = true) => {
    setIsLocaleLoading(true);

    if (content_id)
      try {
        const c_data = await getCommentsOfContent(content_id, myPage);
        if (override) setComments(c_data);
        else setComments([...comments, ...c_data]);

        if (c_data.length === 0) setIsMoreButtonEnabled(false);
      } catch (error) {
        console.log(error);
      }
    else if (bacheca_id)
      try {
        const b_data = await getCommentsOfBacheca(bacheca_id, myPage);
        if (override) setComments(b_data);
        else setComments([...comments, ...b_data]);

        if (b_data.length === 0) setIsMoreButtonEnabled(false);
      } catch (error) {
        console.log(error);
      }

    setIsLocaleLoading(false);
  };

  const viewMoreClick = () => {
    setPage((page) => {
      _loadComments(page + 1, false);
      return page + 1;
    });
  };

  useEffect(() => {
    _loadComments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const postCommentForm = async (e: any) => {
    e.preventDefault();
    if (!e.target.text.value) return;

    if (!user.emailVerified) return navigate("/bacheca/create");

    setIsLocaleLoading(true);

    const payload = formToObject(e.target);

    try {
      await createComment(payload);
      e.target.text.value = "";
    } catch (error) {
      alert(error);
    }

    setIsLocaleLoading(false);

    await _loadComments();
  };

  return (
    <section className="w100 comments-section">
      <form className="mb-4" onSubmit={postCommentForm}>
        <input
          type="text"
          hidden
          readOnly
          name="bacheca_id"
          value={bacheca_id}
        />

        <input
          type="text"
          hidden
          readOnly
          name="content_id"
          value={content_id}
        />
        <label>{t("comments.new")}</label>
        <div className="d-flex align-items-center gap-3 w100">
          <textarea
            className="w100 m-0"
            required
            onInput={autoGrowTextArea}
            name="text"
          />
          <Button
            loading={isLocaleLoading}
            className="m-0"
            small
            type="submit"
            text={t("post")}
          />
        </div>
      </form>
      <div className={`comments ${isExpanded ? "expanded" : ""}`}>
        {comments.map((c) => (
          <Comment
            editors={editors}
            key={"comment_" + c._id}
            className="my-4"
            comment={c}
            onUpdate={_loadComments}
          />
        ))}

        {isMoreButtonEnabled ? (
          <div className="my-5 see-all-btn d-flex justify-content-center">
            {!isLocaleLoading ? (
              <span onClick={viewMoreClick} className="cursor-pointer">
                {t("comments.load_more")}
              </span>
            ) : (
              <Loader scale={0.8} />
            )}
          </div>
        ) : null}
      </div>
      <div className="expand-button">
        {!isExpanded ? (
          <Button
            text={t("comments.expand")}
            small
            onClick={() => setIsExpanded(true)}
          />
        ) : (
          <Button
            text={t("comments.reduce")}
            small
            onClick={() => setIsExpanded(false)}
          />
        )}
      </div>
    </section>
  );
}
