import flowerSvg from "../../assets/svg/flower.svg";

interface Props {
  number: number;
  active?: boolean;
  className?: string;
  onClick?: Function;
}

export default function Likes(props: Props) {
  return (
    <div className={`likes-container ${props.className}`}>
      <div
        className={`likes cursor-pointer ${props.active ? "active" : ""}`}
        onClick={() => props.onClick?.()}
      >
        <span>{props.number}</span>
        <img src={flowerSvg} alt="flower icon" />
      </div>
    </div>
  );
}
