import axios from "axios";
import { setNotificationNumber } from "../store/userSlice";
import { isLoggedIn, iUser } from "./auth";
import { iBacheca } from "./bacheca";
import { iComment } from "./comment";
import { iContent } from "./content";
import store from "./redux";

export enum NOTIFICATION_TYPE {
  WELCOME = "WELCOME",
  CONTENT_PUBLISHED = "CONTENT_PUBLISHED",
  LIKE_BACHECA = "LIKE_BACHECA",
  LIKE_CONTENT = "LIKE_CONTENT",
  LIKE_COMMENT = "LIKE_COMMENT",
  COMMENT_BACHECA = "COMMENT_BACHECA",
  COMMENT_CONTENT = "COMMENT_CONTENT",
}

export interface iNotification {
  _id: string;
  title: string;
  text: string;
  seen: boolean;
  cumulativeAmount: number;
  user: iUser;
  link: string;
  notificationType: NOTIFICATION_TYPE;
  bacheca: iBacheca | string;
  content: iContent | string;
  comment: iComment | string;
  createdAt: any;
  updatedAt: any;
}

export async function getNotifications(
  page: number = 1
): Promise<Array<iNotification>> {
  const response = await axios.get(`/notification?page=${page}`);
  return response.data;
}

export async function seeNotification(id: string): Promise<any> {
  const response = await axios.post("/notification", {
    id: id,
  });
  return response.data;
}

export async function resetNotificationCount(): Promise<any> {
  if (isLoggedIn()) {
    const response = await axios.get("/notification/count");
    store.dispatch(setNotificationNumber(response.data));
    return;
  }
  store.dispatch(setNotificationNumber(0));
}

export async function seeAllNotifications(): Promise<any> {
  const response = await axios.post("/notification/all");
  return response.data;
}
