import { useTranslation } from "react-i18next";
import CompanyInfo from "../components/CompanyInfo";

export default function Partners() {
  const { t } = useTranslation();

  return (
    <main id="partners-page">
      <div className="container">
        <div className="content">
          <div className="text-center">
            <h1 className="h1">{t("partners.title")}</h1>
          </div>
          <div className="mt-5 d-flex flex-column flex-md-row justify-content-around">
            <div className="mx-md-3 my-3 my-md-0 text-center goal">
              <p className="h5">{t("partners.goal_1")}</p>
              <p>{t("partners.goal_1_text")}</p>
            </div>
            <div className="mx-md-3 my-3 my-md-0 text-center goal">
              <p className="h5">{t("partners.goal_2")}</p>
              <p>{t("partners.goal_2_text")}</p>
            </div>
            <div className="mx-md-3 my-3 my-md-0 text-center goal">
              <p className="h5">{t("partners.goal_3")}</p>
              <p>{t("partners.goal_3_text")}</p>
            </div>
          </div>
          <div className="quote text-center mt-5">
            <p className="h5">
              {t("partners.quote")}
              <br />
              <strong>
                <a href="mailto:info@memoramea.com">info@memoramea.com</a>
              </strong>
            </p>
          </div>
        </div>
      </div>
    </main>
  );
}
