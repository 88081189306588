import BachecaCheckoutModal from "./components/modals/BachecaCheckoutModal";
import BachecaDeleteModal from "./components/modals/BachecaDeleteModal";
import BachecaSettingsModal from "./components/modals/BachecaSettingsModal";
import BachecaTransferModal from "./components/modals/BachecaTransferModal";
import CommentDeleteModal from "./components/modals/CommentDeleteModal";
import ContentDeleteModal from "./components/modals/ContentDeleteModal";

export default function Modals() {
  return (
    <>
      <BachecaDeleteModal />
      <CommentDeleteModal />
      <ContentDeleteModal />
      <BachecaSettingsModal />
      <BachecaTransferModal />
      <BachecaCheckoutModal />
    </>
  );
}
