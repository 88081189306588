import { useTranslation } from "react-i18next";

export default function HomeHow() {
  const { t } = useTranslation();

  const data: Array<{ image: any; title: string; text: string }> = [
    {
      title: t("home.how.1.title"),
      text: t("home.how.1.text"),
      image:
        "https://res.cloudinary.com/df37ohxtp/image/upload/v1673258263/Website/5_nobtah.jpg",
    },
    {
      title: t("home.how.2.title"),
      text: t("home.how.2.text"),
      image: "https://res.cloudinary.com/df37ohxtp/image/upload/v1673258263/Website/4_ottfy4.jpg",
    },
    {
      title: t("home.how.3.title"),
      text: t("home.how.3.text"),
      image:
        "https://res.cloudinary.com/df37ohxtp/image/upload/v1673258263/Website/1_rtvonn.jpg",
    },
  ];

  return (
    <section className="home-how">
      <div className="d-flex justify-content-center">
        <h3 className="main-title mb-5">{t("home.how.title")}</h3>
      </div>
      <div className="container">
        <div className="row">
          {data.map((e, key) => {
            return (
              <div key={"how_" + key} className="col">
                <div className="d-flex flex-column align-items-center">
                  <div
                    style={{
                      backgroundImage: `url(${e.image})`,
                      backgroundPosition: "center center",
                      backgroundSize: "cover",
                    }}
                    className="image"
                  ></div>
                  <h5 className="mt-4 text-center">{e.title}</h5>
                  <p className="text-center">{e.text}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
}
