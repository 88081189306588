import axios from "axios";
import { iUser } from "./auth";
import { iBacheca } from "./bacheca";

export enum PROMO_CODE_TYPE {
  DATE_EXPIRY = "DATE_EXPIRY",
  ONE_TIME = "ONE_TIME",
  MULTIPLE_TIME = "MULTIPLE_TIME",
}

export enum DISCOUNT_TYPE {
  FIXED_AMOUNT = "FIXED_AMOUNT",
  FREE = "FREE",
  PERCENTAGE = "PERCENTAGE",
}

export interface iPromoCode {
  _id: string;
  expiryMode: PROMO_CODE_TYPE;
  discountType: DISCOUNT_TYPE;
  discountAmount: number;
  maxUsages: number;
  usedOn: Array<iBacheca | string>;
  code: string;
  expireDate: Date;
  user: string | iUser;
}

export async function getPromoCodes(
  page: number = 1
): Promise<Array<iPromoCode>> {
  const response = await axios.get(`/promo-code?page=${page}`);
  return response.data;
}

export async function createPromoCode(payload: any) {
  const response = await axios.post("/promo-code", payload);
  return response.data;
}

export async function deletePromoCode(id: string) {
  const response = await axios.delete("/promo-code", {
    data: {
      promocode_id: id,
    },
  });
  return response.data;
}

export async function getPromoCodePublic(): Promise<iPromoCode> {
  const response = await axios.get(`/promo-code-public`);
  return response.data;
}

export async function createPromoCodePublic(payload: any) {
  const response = await axios.post("/promo-code-public", payload);
  return response.data;
}

export async function deletePromoCodePublic(id: string) {
  const response = await axios.delete("/promo-code-public", {
    data: {
      promocode_id: id,
    },
  });
  return response.data;
}
