import axios from "axios";
import { setLikes } from "../store/userSlice";
import { isLoggedIn, iUser } from "./auth";
import { iBacheca } from "./bacheca";
import { iComment } from "./comment";
import { iContent } from "./content";
import store from "./redux";

export interface iLike {
  _id: string;
  user: iUser | string;
  content: iContent | string;
  comment: iComment | string;
  bacheca: iBacheca | string;
  createdAt: any;
}

export async function addLike(payload: any) {
  const response = await axios.post("/like", payload);
  return response.data;
}

export async function removeLike(id: string) {
  const response = await axios.delete("/like", { data: { like_id: id } });
  return response.data;
}

export async function loadUserLikes(payload: {
  content_ids?: Array<string>;
  bacheca_ids?: Array<string>;
  comment_ids?: Array<string>;
}): Promise<Array<iLike>> {
  try {
    if (!isLoggedIn()) throw new Error("Not logged in");
    const response = await axios.post("/like/my-likes", payload);

    const likes = [...store.getState().user.likes];

    (response.data as Array<iLike>).forEach((l) => {
      if (likes.some((e) => e._id === l._id)) return;
      likes.push(l);
    });

    store.dispatch(setLikes(likes));

    return response.data;
  } catch (error) {
    store.dispatch(setLikes([]));
    return [];
  }
}

export function userLikes(id: string): boolean {
  const likes = store.getState().user.likes;

  return likes.some(
    (e) => e.bacheca === id || e.comment === id || e.content === id
  );
}

export function removeLocalLike(id: string) {
  const likes = [...store.getState().user.likes];

  const filtered = likes.filter(
    (e) => e.bacheca !== id && e.comment !== id && e.content !== id
  );

  store.dispatch(setLikes(filtered));
}

export function addLocalLike(id: string) {
  const likes = [...store.getState().user.likes];
  likes.push({ bacheca: id, content: id, comment: id } as any);
  store.dispatch(setLikes(likes));
}
