import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import it from "../translations/it.json";
import en from "../translations/en.json";
import axios from "axios";

export const LOCALE_LANGUAGE_KEY = "4v6325b47685n7";
export const DEFAULT_LANGUAGE = "it";

export enum LANGUAGE_BY_LOCALE {
  en = "en",
  it = "it",
}

const resources: { [lang in LANGUAGE_BY_LOCALE]: { translation: any } } = {
  en: {
    translation: en,
  },
  it: {
    translation: it,
  },
};

export default i18next.use(initReactI18next).init({
  resources,
  lng: DEFAULT_LANGUAGE,
  interpolation: {
    escapeValue: false,
  },
});

export function setLocaleLanguage(lang: LANGUAGE_BY_LOCALE) {
  localStorage.setItem(LOCALE_LANGUAGE_KEY, String(lang));
  i18next.changeLanguage(lang);
}

export async function getLocaleLanguage(): Promise<string> {
  const langFromStorage = localStorage.getItem(LOCALE_LANGUAGE_KEY);
  if (langFromStorage) return String(langFromStorage);

  const langFromIp = await getLangFromIp();
  if (langFromIp) {
    setLocaleLanguage(langFromIp as any);
    return langFromIp;
  }

  setLocaleLanguage(DEFAULT_LANGUAGE as any);

  return DEFAULT_LANGUAGE;
}

export async function initi18next() {
  i18next.changeLanguage(await getLocaleLanguage());
}

async function getLangFromIp(): Promise<string | undefined> {
  try {
    const response = await axios.get("https://ipapi.co/json/");
    const lang = response.data.languages.split(",")[0].split("-")[0];

    if (!Object.values(LANGUAGE_BY_LOCALE).includes(lang))
      throw new Error("This lang is not defined in Lang Enum:", lang);

    return lang;
  } catch (error) {
    console.log("Error getting the lang from IP", error);
  }

  return undefined;
}
