import { useTranslation } from "react-i18next";

export default function HomeQuote() {
  const { t } = useTranslation();

  return (
    <section className="home-quote w100 p-5">
      <h5 className="text-center text-light display-5">
        {t("home.quote.quote")}
      </h5>
      <h6 className="h6 text-center text-light">- {t("home.quote.author")}</h6>
    </section>
  );
}
