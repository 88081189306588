import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  getPromoCodePublic,
  deletePromoCodePublic,
  iPromoCode,
  createPromoCodePublic,
} from "../../services/promocode";
import { removeSpecialCharacters } from "../../utils/generic";
import Button from "../dom/Button";

interface Props {
  onClick?: Function;
}

export default function PromoBox(props: Props) {
  const [promo, setPromo] = useState<iPromoCode | any>({});
  const [isLocaleLoading, setIsLocaleLoading] = useState(false);
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);

  const { t } = useTranslation();

  const loadPromo = async () => {
    setIsLocaleLoading(true);
    try {
      const promo = await getPromoCodePublic();
      if (promo) setPromo(promo);
    } catch (error) {
      console.log(error);
    }
    setIsLocaleLoading(false);
  };

  const savePromo = async () => {
    setIsLocaleLoading(true);
    try {
      if (promo._id) await deletePromoCodePublic(promo._id);

      await createPromoCodePublic({ code: promo.code });
      await loadPromo();
      setIsSaveDisabled(true);
    } catch (error) {
      console.log(error);
    }
    setIsLocaleLoading(false);
  };

  useEffect(() => {
    loadPromo();
    // eslint-disable-next-line
  }, []);

  return (
    <div onClick={() => props.onClick?.()} className="promo-box">
      <span className="h4">{t("promo.title")}</span>
      <p>{t("promo.text")}</p>
      <div className="w100">
        <input
          className="w100"
          type="text"
          value={promo?.code ?? ""}
          onChange={(e) => {
            const val = removeSpecialCharacters(e.target.value);
            setIsSaveDisabled(false);
            setPromo({ ...promo, code: val });
          }}
        />
        <p className="mt-2">
          {t("promo.times_used")}: {promo?.usedOn?.length}
        </p>
        <p className="mt-2">
          {t("promo.value")}: € {promo?.discountAmount}
        </p>
        <Button
          disabled={isSaveDisabled || !promo?.code}
          loading={isLocaleLoading}
          onClick={() => savePromo()}
          text={t("promo.save")}
        />
      </div>
    </div>
  );
}
