import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { iUser } from "../../services/auth";
import {
  addBachecaEditor,
  getEditorsOfBacheca,
  removeBachecaEditor,
} from "../../services/bacheca";
import { useAppDispatch } from "../../services/redux";
import {
  selectBachecaSettingsModalOpen,
  setIsBachecaSettingsModalOpen,
} from "../../store/appSlice";
import { formToObject } from "../../utils/generic";
import Button from "../dom/Button";

export default function BachecaSettingsModal() {
  const data = useSelector(selectBachecaSettingsModalOpen);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [isLocaleLoading, setIsLocaleLoading] = useState(false);
  const [editors, setEditors] = useState<Array<iUser>>([]);

  const [error, setError] = useState("");

  const addEditorForm = async (e: any) => {
    setError("");
    e.preventDefault();
    if (!e.target.user_email.value) return;

    setIsLocaleLoading(true);

    try {
      const payload = formToObject(e.target);
      await addBachecaEditor(payload);
      e.target.user_email.value = "";
    } catch (error: any) {
      setError(error?.response?.data?.message);
      console.log(error);
    }

    setIsLocaleLoading(false);
    loadEditors();
  };

  const removeEditorForm = async (email: string) => {
    if (!data) return;

    setIsLocaleLoading(true);

    try {
      await removeBachecaEditor({
        bacheca_id: data.bacheca._id,
        user_email: email,
      });
    } catch (error) {
      console.log(error);
    }

    setIsLocaleLoading(false);
    loadEditors();
  };

  const loadEditors = async () => {
    if (!data) return;

    setIsLocaleLoading(true);
    try {
      const _editors = await getEditorsOfBacheca(data.bacheca._id);
      setEditors(_editors);
    } catch (error) {
      console.log(error);
    }
    setIsLocaleLoading(false);
  };

  useEffect(() => {
    loadEditors();
    // eslint-disable-next-line
  }, [data]);

  if (!data) return <></>;

  return (
    <div
      className="custom-modal-container"
      onClick={() => dispatch(setIsBachecaSettingsModalOpen(undefined))}
    >
      <div onClick={(e) => e.stopPropagation()} className="custom-modal">
        <p className="mt-5 mb-4 h4">
          {t("access")} {data.bacheca.name}
        </p>

        {editors.map((e, key) => {
          return (
            <div
              key={"editor_" + key}
              className="d-flex align-items-center gap-2"
            >
              <span>{e.email}</span>
              <span
                onClick={() => {
                  removeEditorForm(e.email);
                }}
                className="px-3 cursor-pointer text-danger"
              >
                {t("remove")}
              </span>
            </div>
          );
        })}

        <form
          className="mt-4 d-flex flex-row align-items-center gap-2"
          onSubmit={addEditorForm}
        >
          <input
            type="text"
            name="bacheca_id"
            hidden
            readOnly
            value={data.bacheca._id}
          />
          <input
            type="text"
            id="user_email"
            name="user_email"
            className="m-0 w100"
          />
          <Button
            loading={isLocaleLoading}
            small
            type="submit"
            text={t("add")}
          />
        </form>

        <span className="text-danger">{error}</span>

        <div
          onClick={() => dispatch(setIsBachecaSettingsModalOpen(undefined))}
          className="close"
        >
          X
        </div>

        <div className="actions">
          {/* <Button
            onClick={() => {
              settingsBachecaButton(data.bacheca._id);
            }}
            loading={isLocaleLoading}
            light
            text={t("save")}
          /> */}
        </div>
      </div>
    </div>
  );
}
