import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../services/redux";
import {
  createFreeIntent,
  createPaymentIntent,
  getPaymentPreview,
  iPaymentPreview,
} from "../../services/stripe";
import {
  selectCheckoutModalOpen,
  setIsCheckoutModalOpen,
} from "../../store/appSlice";
import Button from "../dom/Button";

import {
  CardElement,
  Elements,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Select from "../dom/Select";
import { BACHECA_PAY_STATUS, getMyBacheca } from "../../services/bacheca";

const stripe = loadStripe(String(process.env.REACT_APP_STRIPE_KEY));

interface Props {
  bachecaId: string;
  onSuccess: Function;
  promoCode?: string;
  billingDetails: object;
  disabled: boolean;
}

function CheckoutForm({
  bachecaId,
  onSuccess,
  promoCode,
  billingDetails,
  disabled,
}: Props) {
  const { t } = useTranslation();
  const [clientSecret, setClientSecret] = useState("");
  const stripe = useStripe();
  const elements = useElements();
  const [isLocaleLoading, setIsLocaleLoading] = useState(false);
  const [error, setError] = useState("");

  const getClientSecret = async () => {
    setIsLocaleLoading(true);

    try {
      const response = await createPaymentIntent(
        bachecaId,
        billingDetails,
        promoCode
      );

      setClientSecret(response.client_secret);
    } catch (error) {
      console.log(error);
    }

    setIsLocaleLoading(false);
  };

  useEffect(() => {
    getClientSecret();
    // eslint-disable-next-line
  }, [bachecaId, promoCode]);

  const paySubmit = async (e: any) => {
    e.preventDefault();
    setIsLocaleLoading(true);

    try {
      const payload = await stripe?.confirmCardPayment(clientSecret, {
        payment_method: {
          card: elements?.getElement(CardElement)!,
        },
      });

      if (payload?.error) setError(t("checkout.payment_failed"));
      else onSuccess();
    } catch (error) {
      console.log(error);
    }

    setIsLocaleLoading(false);
  };

  return (
    <>
      <form className="checkout-form" onSubmit={paySubmit}>
        <CardElement
          className="input"
          onChange={(e) => {
            setError(e.error ? e.error.message : "");
          }}
        />
        <Button
          loading={isLocaleLoading}
          type="submit"
          text={t("checkout.pay")}
          disabled={disabled}
        />
        <p className="text-danger">{error}</p>
      </form>
    </>
  );
}

function Checkout({
  bachecaId,
  onSuccess,
  promoCode,
  billingDetails,
  disabled,
}: Props) {
  return (
    <Elements stripe={stripe}>
      <CheckoutForm
        disabled={disabled}
        onSuccess={onSuccess}
        bachecaId={bachecaId}
        billingDetails={billingDetails}
        promoCode={promoCode}
      />
    </Elements>
  );
}

export default function BachecaCheckoutModal() {
  const data = useSelector(selectCheckoutModalOpen);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [isLocaleLoading, setIsLocaleLoading] = useState(false);
  const [promoError, setPromoError] = useState(false);
  const [paymentPreview, setPaymentPreview] = useState<iPaymentPreview>();
  const [promoCode, setPromoCode] = useState("");
  const [isCheckout, setIsCheckout] = useState(false);
  const [billing, setBilling] = useState<any>({});
  const [billingMode, setBillingMode] = useState<"private" | "company" | "">(
    ""
  );

  const loadPaymentPreview = async (discountCode?: string) => {
    if (!data) return;
    setIsLocaleLoading(true);

    try {
      const pp = await getPaymentPreview(data.bacheca._id, discountCode);
      setPaymentPreview(pp);

      if (discountCode)
        if (pp.price < pp.originalPrice) setPromoCode(discountCode);
        else throw new Error("Promo code not working");
    } catch (error) {
      console.log(error);
      setPromoError(true);
      setTimeout(() => setPromoError(false), 1000);
    }

    setIsLocaleLoading(false);
  };

  useEffect(() => {
    loadPaymentPreview();
    // eslint-disable-next-line
  }, [data]);

  if (!data) return <></>;

  return (
    <div
      className="custom-modal-container"
      onClick={() => dispatch(setIsCheckoutModalOpen(undefined))}
    >
      <div onClick={(e) => e.stopPropagation()} className="custom-modal">
        <div className="row mt-5">
          <div className="col">
            <div>
              <p className="h6 m-0">{t("checkout.checkout_text")}</p>
              <p className="h2">{data.bacheca.name}</p>
            </div>
          </div>
          <div className="col">
            <div className="d-flex h100 justify-content-end align-items-center">
              <p className="h6 m-0">
                {paymentPreview?.originalPrice?.toFixed(2)}€
              </p>
            </div>
          </div>
        </div>
        <div className="hr"></div>

        <div className="d-flex align-items-center justify-content-end gap-1">
          <input
            type="text"
            placeholder={t("checkout.discount_code")}
            id="promo-code-input"
            className={promoError ? "bg-danger" : ""}
            onInput={() => setPromoError(false)}
          />
          <Button
            onClick={() =>
              loadPaymentPreview(
                (document.getElementById("promo-code-input") as any).value
              )
            }
            small
            text={t("checkout.apply_discount")}
            loading={isLocaleLoading}
          />
        </div>

        <div className="hr mb-5"></div>

        {Number(paymentPreview?.originalPrice) >
        Number(paymentPreview?.price) ? (
          <div className="row">
            <div className="col">
              <div className="d-flex h100">
                <p className="h6 m-0 mt-auto">{t("checkout.discount")}</p>
              </div>
            </div>
            <div className="col">
              <div className="d-flex h100 justify-content-end align-items-center">
                <p className="h4 m-0">
                  -
                  {(
                    Number(paymentPreview?.originalPrice) -
                    Number(paymentPreview?.price)
                  ).toFixed(2)}
                  €
                </p>
              </div>
            </div>
          </div>
        ) : null}

        <div className="row">
          <div className="col">
            <div className="d-flex h100">
              <p className="h3 m-0 mt-auto">{t("checkout.total")}</p>
            </div>
          </div>
          <div className="col">
            <div className="d-flex h100 justify-content-end align-items-center">
              <p className="h1 m-0">{paymentPreview?.price?.toFixed(2)}€</p>
            </div>
          </div>
        </div>

        <div className="hr"></div>
        <fieldset disabled={isCheckout}>
          <form>
            <label htmlFor="">{t("billing.type")}</label>
            <Select
              className="mb-2"
              options={[
                { text: t("billing.select"), value: "" },
                { text: t("billing.private"), value: "private" },
                { text: t("billing.company"), value: "company" },
              ]}
              onChange={(e: any) => {
                setBillingMode(e.target.value);
                setBilling({});
              }}
            />
            {billingMode === "private" ? (
              <>
                <label htmlFor="">{t("billing.cf")}*</label>
                <input
                  onChange={(e) => {
                    setBilling({ ...billing, cf: e.target.value });
                  }}
                  type="text"
                  name="cf"
                />
                <div className="d-flex gap-2">
                  <div className="d-flex flex-column w100">
                    <label htmlFor="">{t("billing.first_name")}</label>
                    <input
                      onChange={(e) => {
                        setBilling({
                          ...billing,
                          first_name: e.target.value,
                        });
                      }}
                      type="text"
                      name="firstName"
                    />
                  </div>
                  <div className="d-flex flex-column w100">
                    <label htmlFor="">{t("billing.second_name")}</label>
                    <input
                      onChange={(e) => {
                        setBilling({
                          ...billing,
                          second_name: e.target.value,
                        });
                      }}
                      type="text"
                      name="secondName"
                    />
                  </div>
                </div>
                <div className="d-flex gap-2">
                  <div className="d-flex flex-column w100">
                    <label htmlFor="">{t("billing.cap")}*</label>
                    <input
                      onChange={(e) => {
                        setBilling({ ...billing, cap: e.target.value });
                      }}
                      type="text"
                      name="cap"
                    />
                  </div>
                  <div className="d-flex flex-column w100">
                    <label htmlFor="">{t("billing.city")}</label>
                    <input
                      onChange={(e) => {
                        setBilling({ ...billing, city: e.target.value });
                      }}
                      type="text"
                      name="city"
                    />
                  </div>
                </div>
                <label htmlFor="">{t("billing.address")}*</label>
                <input
                  onChange={(e) => {
                    setBilling({ ...billing, address: e.target.value });
                  }}
                  type="text"
                  name="address"
                />
              </>
            ) : null}
            {billingMode === "company" ? (
              <>
                <label htmlFor="">{t("billing.iva")}*</label>
                <input
                  onChange={(e) => {
                    setBilling({ ...billing, iva: e.target.value });
                  }}
                  type="text"
                  name="iva"
                />
                <label htmlFor="">{t("billing.cf")}</label>
                <input
                  onChange={(e) => {
                    setBilling({ ...billing, cf: e.target.value });
                  }}
                  type="text"
                  name="cf"
                />
                <label htmlFor="">{t("billing.name")}</label>
                <input
                  onChange={(e) => {
                    setBilling({ ...billing, name: e.target.value });
                  }}
                  type="text"
                  name="name"
                />
                <div className="d-flex gap-2">
                  <div className="d-flex flex-column w100">
                    <label htmlFor="">{t("billing.cap")}*</label>
                    <input
                      onChange={(e) => {
                        setBilling({ ...billing, cap: e.target.value });
                      }}
                      type="text"
                      name="cap"
                    />
                  </div>
                  <div className="d-flex flex-column w100">
                    <label htmlFor="">{t("billing.city")}</label>
                    <input
                      onChange={(e) => {
                        setBilling({ ...billing, city: e.target.value });
                      }}
                      type="text"
                      name="city"
                    />
                  </div>
                </div>
                <label htmlFor="">{t("billing.address")}*</label>
                <input
                  onChange={(e) => {
                    setBilling({ ...billing, address: e.target.value });
                  }}
                  type="text"
                  name="address"
                />
                <div className="d-flex gap-2">
                  <div className="d-flex flex-column w100">
                    <label htmlFor="">{t("billing.contact")}</label>
                    <input
                      onChange={(e) => {
                        setBilling({ ...billing, contact: e.target.value });
                      }}
                      type="text"
                      name="contact"
                    />
                  </div>
                  <div className="d-flex flex-column w100">
                    <label htmlFor="">{t("billing.pec")}</label>
                    <input
                      onChange={(e) => {
                        setBilling({ ...billing, pec: e.target.value });
                      }}
                      type="email"
                      name="pec"
                    />
                  </div>
                </div>
              </>
            ) : null}
          </form>
        </fieldset>
        {isCheckout ? (
          <>
            {billingMode ? (
              <Checkout
                onSuccess={() => {
                  setIsCheckout(false);
                  data.callback();
                  dispatch(setIsCheckoutModalOpen(undefined));
                  setInterval(async () => {
                    const b = await getMyBacheca(data.bacheca._id);
                    if (b.payStatus === BACHECA_PAY_STATUS.PAID)
                      window.location.reload();
                  }, 500);
                }}
                bachecaId={data.bacheca._id}
                promoCode={promoCode}
                billingDetails={billing} // compile billing details
                disabled={
                  !billingMode ||
                  (billingMode === "company" &&
                    (!billing.iva || !billing.address || !billing.cap)) ||
                  (billingMode === "private" &&
                    (!billing.cf || !billing.address || !billing.cap))
                }
              />
            ) : null}
          </>
        ) : (
          <Button
            loading={isLocaleLoading}
            className="w100 mt-3"
            text={t("checkout.proceed")}
            onClick={async () => {
              if (paymentPreview?.price === 0) {
                setIsLocaleLoading(true);
                await createFreeIntent(data.bacheca._id, promoCode);
                window.location.reload();
                return;
              }
              setIsCheckout(true);
            }}
            disabled={
              !billingMode ||
              (billingMode === "company" &&
                (!billing.iva || !billing.address || !billing.cap)) ||
              (billingMode === "private" &&
                (!billing.cf || !billing.address || !billing.cap))
            }
          />
        )}

        <div
          onClick={() => {
            setIsCheckout(false);
            dispatch(setIsCheckoutModalOpen(undefined));
          }}
          className="close"
        >
          X
        </div>
      </div>
    </div>
  );
}
