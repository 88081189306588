import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
// import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../../components/dom/Button";
import Stepper from "../../components/generic/Stepper";
// import { isLoggedIn } from "../../services/auth";
import {
  createContent,
  getContent,
  iContent,
  patchContent,
} from "../../services/content";
// import { selectUser } from "../../store/userSlice";
import {
  autoGrowTextArea,
  compressImage,
  formToObject,
} from "../../utils/generic";
import { APPROVAL_RULE } from "../../services/bacheca";
import { closeModal, openModal } from "../../utils/modal";

export default function ContentForm() {
  const { t } = useTranslation();
  const STEPS = ["step_basic", "step_images", "step_preview"];
  const tSTEPS = STEPS.map((s) => t("content_form." + s));
  const [step, setStep] = useState(0);
  const [, updateComponent] = useState<any>();
  const [contentPreview, setContentPreview] = useState<any>({});
  const navigate = useNavigate();

  const [content, setContent] = useState<iContent>();
  const [content_id, setContentId] = useState("");
  const [bacheca_id, setBachecaId] = useState("");

  const [imagesPreview, setImagesPreview] = useState<Array<any>>([]);

  const { contentId } = useParams();
  const { bachecaId } = useParams();

  const [isLocaleLoading, setIsLocaleLoading] = useState(false);

  // const isLogged = isLoggedIn();
  // const user = useSelector(selectUser);

  const lorem20 =
    "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Consequatur reprehenderit sed distinctio laborum tempore delectus minus, doloremque quo, commodi maiores odio reiciendis vero repellat cum assumenda rem dicta voluptas quos saepe ipsum, provident cupiditate sapiente ad. Est beatae sint numquam.";

  const _initContent = async () => {
    if (bachecaId) setBachecaId(bachecaId);

    if (!contentId) return;
    if (contentId) setContentId(contentId);

    setIsLocaleLoading(true);

    try {
      const data = await getContent(contentId);
      setContent(data);
    } catch (error) {
      console.log(error);
    }

    setIsLocaleLoading(false);
  };

  useEffect(() => {
    _initContent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const contentForm = async (e: any) => {
    e.preventDefault();
    setIsLocaleLoading(true);

    try {
      const payload = formToObject(e.target);

      let content;

      if (payload.content_id) content = await patchContent(payload);
      else content = await createContent(payload);

      if (content.approvedStatus === APPROVAL_RULE.TO_APPROVE)
        openModal({
          content: (
            <div className="d-flex flex-column gap-2 align-items-center jsutify-content-center p-3">
              <strong>{t("content.to_approve_title")}</strong>
              <span>{t("content.to_approve_text")}</span>
              <span></span>

              <Button text="Ok" onClick={() => closeModal()} />
            </div>
          ),
        });

      navigate("/bacheca/" + bacheca_id);
    } catch (error) {
      console.log(error);
    }

    setIsLocaleLoading(false);
  };

  const stepClick = (direction: "back" | "forwards") => {
    if (direction === "back" && step > 0) {
      setStep(step - 1);
    }

    if (direction === "forwards" && step < STEPS.length - 1) {
      setStep(step + 1);
    }

    initContentPreview();
  };

  const imagesUploadInput = (e: any) => {
    const images = Array.from(e.target.files);

    images.forEach(async (image: any) => {
      const readAsync = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(image);

        reader.onloadend = async () => {
          if (reader.result) {
            const compressedImage = await compressImage(String(reader.result));
            resolve(compressedImage);
          }
        };
      });

      setImagesPreview((p) => {
        if (p.length >= 10) return p;
        p.push(readAsync);
        return p;
      });
      updateComponent(Math.random());
    });
  };

  const initContentPreview = () => {
    const form = document.getElementById("content-form");
    const data = formToObject(form, false);
    setContentPreview(data);
  };

  // if (!isLogged)
  //   return (
  //     <main className="container">
  //       <div className="w100 mt-3 d-flex flex-column align-items-center">
  //         <span>{t("content_form.no_login_error")}</span>
  //         <Button
  //           onClick={() => {
  //             // todo: change this with navigate to avoid reload.
  //             window.location.href = "/profile";
  //           }}
  //           text="login"
  //           className="mt-4"
  //         />
  //       </div>
  //     </main>
  //   );

  // if (!user.emailVerified)
  //   return (
  //     <main className="container">
  //       <div className="w100 mt-3 d-flex flex-column align-items-center">
  //         <span>{t("board_form.no_verified_error")}</span>
  //         <Button
  //           onClick={() => {
  //             // todo: change this with navigate to avoid reload.
  //             window.location.href = "/profile";
  //           }}
  //           text={t("auth.profile")}
  //           className="mt-4"
  //         />
  //       </div>
  //     </main>
  //   );

  const stepper = (
    <Stepper
      steps={tSTEPS}
      onChange={(s: any) => {
        setStep(s);
        initContentPreview();
      }}
      currentStep={step}
      className="mt-5 mx-2"
    />
  );

  const pageButtons = (
    <div className="d-flex w100 mt-5 mx-2 px-3">
      {step > 0 ? (
        <Button
          secondary
          small
          onClick={() => stepClick("back")}
          text={t("content_form.back")}
          className="mr-auto"
        />
      ) : null}
      {step < STEPS.length - 1 ? (
        <Button
          className="ml-auto"
          small
          onClick={() => stepClick("forwards")}
          text={t("content_form.next")}
        />
      ) : null}
      {step === STEPS.length - 1 ? (
        <Button
          loading={isLocaleLoading}
          className="save-button ml-auto"
          type="submit"
          text={
            isLocaleLoading
              ? t("content_form.uploading")
              : bacheca_id
              ? t("content_form.save")
              : t("content_form.create")
          }
        />
      ) : null}
    </div>
  );

  const stepBasic = (
    <div className={`step ${STEPS[step] === "step_basic" ? "active" : ""}`}>
      <label htmlFor="form-title">{t("content_form.title")}</label>
      <input
        type="text"
        name="title"
        id="form-title"
        defaultValue={content?.title}
        required
      />
      <label htmlFor="form-text">{t("content_form.text")}</label>
      <textarea
        required
        onInput={autoGrowTextArea}
        id="form-text"
        name="text"
        defaultValue={content?.text}
      />
    </div>
  );

  const stepImages = (
    <div className={`step ${STEPS[step] === "step_images" ? "active" : ""}`}>
      <input
        onChange={imagesUploadInput}
        type="file"
        id="form-images"
        accept="image/png, image/jpeg"
        multiple
        hidden
      />
      <div className="d-flex justify-content-center mb-4">
        <Button
          metadata={`${imagesPreview.length}/10`}
          text={t("content_form.upload_images")}
          onClick={() => {
            document.getElementById("form-images")?.click();
          }}
        />
      </div>
      <div className="img-preview-container">
        {imagesPreview.map((i, key) => {
          return (
            <div key={key} className="img-preview">
              <input readOnly type="text" hidden value={i} name="images[]" />
              <div
                onClick={() => {
                  setImagesPreview((p) => {
                    p = p.filter((e, _key) => _key !== key);
                    return p;
                  });
                }}
                className="delete cursor-pointer"
              >
                <span className="m-0">x</span>
              </div>
              <div
                style={{
                  backgroundImage: `url(${i})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center center",
                }}
                className="image"
              ></div>
            </div>
          );
        })}
      </div>
    </div>
  );

  const stepPreview = (
    <div className={`step ${STEPS[step] === "step_preview" ? "active" : ""}`}>
      <div className="d-flex flex-column align-items-center">
        <span className="h3">
          {contentPreview.title ?? t("content_form.title")}
        </span>
        <p className="text-center">{contentPreview.text ?? lorem20}</p>
      </div>
      <div className="img-step-preview">
        {contentPreview.images?.map((i: any, key: any) => {
          return (
            <div
              key={"preview_step_image_" + key}
              style={{
                backgroundImage: `url(${i})`,
                backgroundSize: "cover",
                backgroundPosition: "center center",
              }}
              className="image"
            ></div>
          );
        })}
      </div>
    </div>
  );

  return (
    <main className="container content-form-container">
      <Button
        text={"< " + t("content_form.return_to_bacheca")}
        onClick={() => navigate("/bacheca/" + bacheca_id)}
        small
        className="mb-4"
      />
      <div className="content-form">
        {stepper}
        <div className="hr light"></div>
        <form id="content-form" className="mx-2" onSubmit={contentForm}>
          <input
            readOnly
            hidden
            type="text"
            name="content_id"
            defaultValue={content_id}
          />
          <input
            readOnly
            hidden
            type="text"
            name="bacheca_id"
            defaultValue={bacheca_id}
          />

          {stepBasic}
          {stepImages}
          {stepPreview}
          {pageButtons}
        </form>
      </div>
    </main>
  );
}
