import axios from "axios";
import { setUser } from "../store/userSlice";
import { LANGUAGE_BY_LOCALE } from "./i18next";
import { resetNotificationCount } from "./notification";
import store from "./redux";

const TOKEN_KEY = "n87pb65948c7v59b6";

export const AVATAR_DEFAULT =
  "https://res.cloudinary.com/df37ohxtp/image/upload/v1673910531/Website/avatar-placeholder_y6jh3m.png";

export const COVER_DEFAULT =
  "https://res.cloudinary.com/df37ohxtp/image/upload/v1673910702/Website/panoramic-landscape-paintingblack-white-green-palace_igf79j.jpg";

export enum USER_TYPE {
  DEFAULT = "DEFAULT",
  COMPANY = "COMPANY",
  ADMIN = "ADMIN",
}

interface iLocation {
  address: string;
  city: string;
  region: string;
  country: string;
  province: string;
  lat: string;
  lng: string;
}

export interface iUser {
  _id: string;
  email: string;
  emailVerified: boolean;
  lang: LANGUAGE_BY_LOCALE;
  userType: USER_TYPE;
  bio: string;
  coverImage: string;
  profileImage: string;
  name: string;
  location?: iLocation;
  phone: string;
  website: string;
  password: string;
  newsletterEmail: boolean;
  infoEmail: boolean;
  isAdmin: boolean;
  createdAt: any;
  updatedAt: any;
}

export async function register(payload: any) {
  const response = await axios.post("/auth/register", payload);
  return response.data;
}

export async function login(payload: any) {
  const response = await axios.post("/auth/login", payload);

  const token = response.data?.token;
  // eslint-disable-next-line
  if (!token) throw { ...response.data };

  axios.defaults.headers.common["Authorization"] = "Bearer " + token;

  window.localStorage.setItem(TOKEN_KEY, token);

  resetNotificationCount();

  return response.data;
}

export async function refreshUserInfo() {
  const token = window.localStorage.getItem(TOKEN_KEY);
  if (!token) throw new Error("Token not found");

  const response = await axios.get("/auth");

  store.dispatch(setUser(response.data));

  resetNotificationCount();

  return response.data;
}

export async function sendVerificaitonEmail() {
  const token = window.localStorage.getItem(TOKEN_KEY);
  if (!token) throw new Error("Token not found");

  const response = await axios.get("/auth/verify-email");

  return response.data;
}

export function isLoggedIn() {
  try {
    return window.localStorage.getItem(TOKEN_KEY) ? true : false;
  } catch (error) {
    return false;
  }
}

export async function logout() {
  axios.defaults.headers.common["Authorization"] = "";

  store.dispatch(setUser({}));

  resetNotificationCount();

  window.localStorage.removeItem(TOKEN_KEY);
}

export async function verifyEmail(verifytoken: string) {
  if (!verifytoken) throw new Error("Token not found");

  const response = await axios.post("/auth/verify-email", {
    verifytoken,
  });

  const token = response.data?.token;
  // eslint-disable-next-line
  if (token) {
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    window.localStorage.setItem(TOKEN_KEY, token);
    resetNotificationCount();
  }

  return response.data;
}

export function initializeLoggedUser() {
  const token = window.localStorage.getItem(TOKEN_KEY);
  if (token) {
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    refreshUserInfo();
  }
}

export async function sendRecoveryPasswordEmail(email: string) {
  const response = await axios.post("/auth/reset-password", {
    email,
  });

  return response.data;
}

export async function resetPassword(token: string, password: string) {
  const response = await axios.patch("/auth/reset-password", {
    token,
    password,
  });

  return response.data;
}

export async function patchUser(payload: any): Promise<iUser> {
  const response = await axios.patch("/auth", payload);
  return response.data;
}

export async function getUserProfile(id: string): Promise<iUser> {
  const response = await axios.get("/auth/" + id);
  return response.data;
}

export async function getAllPartners(): Promise<iUser[]> {
  const response = await axios.get("/partners");
  return response.data;
}
