import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { USER_TYPE, patchUser, refreshUserInfo } from "../../services/auth";
import { LANGUAGE_BY_LOCALE } from "../../services/i18next";
import { selectUser } from "../../store/userSlice";
import { autoGrowTextArea, formToObject } from "../../utils/generic";
import Button from "../dom/Button";
import Checkbox from "../dom/Checkbox";
import Select from "../dom/Select";

interface Props {
  onSaveClick: Function;
}

export default function SettingsBox({ onSaveClick }: Props) {
  const user = useSelector(selectUser);
  const { t } = useTranslation();
  const [isLocaleLoading, setIsLocaleLoading] = useState(false);
  const [isSaveSuccess, setIsSaveSuccess] = useState(false);
  const languageOptions = Object.keys(LANGUAGE_BY_LOCALE);

  const [mode, setMode] = useState<USER_TYPE>(user.userType);

  const userForm = async (e: any) => {
    e.preventDefault();
    setIsLocaleLoading(true);

    const payload = formToObject(e.target);
    payload.user_type = mode;

    try {
      await patchUser(payload);
      await refreshUserInfo();
      setIsSaveSuccess(true);
      onSaveClick();
    } catch (error) {
      console.log(error);
    }

    setIsLocaleLoading(false);
  };

  return (
    <>
      <span className="mt-3 h4">{t("settings.title")}</span>

      <div className="settings mt-2 mb-5 w100">
        <form onSubmit={userForm}>
          <label htmlFor="settings-name">{t("name")}</label>
          <input
            required
            id="settings-name"
            type="text"
            name="name"
            defaultValue={user.name}
          />

          <label htmlFor="settings-bio">{t("settings.bio")}</label>
          <textarea
            name="bio"
            onInput={autoGrowTextArea}
            id="settings-bio"
            defaultValue={user.bio}
          ></textarea>

          {mode === USER_TYPE.COMPANY ? (
            <>
              <label htmlFor="settings-website">{t("settings.website")}</label>
              <input
                id="settings-website"
                type="text"
                name="website"
                defaultValue={user.website}
              />

              <label htmlFor="settings-phone">{t("settings.phone")}</label>
              <input
                id="settings-phone"
                name="phone"
                type="tel"
                defaultValue={user.phone}
              />

              <label htmlFor="settings-location-country">
                {t("settings.location-country")}
              </label>
              <input
                id="settings-location-country"
                name="location->country"
                type="text"
                defaultValue={user.location?.country}
              />

              <label htmlFor="settings-location-region">
                {t("settings.location-region")}
              </label>
              <input
                id="settings-location-region"
                name="location->region"
                type="text"
                defaultValue={user.location?.region}
              />

              <label htmlFor="settings-location-province">
                {t("settings.location-province")}
              </label>
              <input
                id="settings-location-province"
                name="location->province"
                type="text"
                defaultValue={user.location?.province}
              />

              <label htmlFor="settings-location-city">
                {t("settings.location-city")}
              </label>
              <input
                id="settings-location-city"
                name="location->city"
                type="text"
                defaultValue={user.location?.city}
              />

              <label htmlFor="settings-location-address">
                {t("settings.location-address")}
              </label>
              <input
                id="settings-location-address"
                name="location->address"
                type="text"
                defaultValue={user.location?.address}
              />
            </>
          ) : null}

          <div className="d-flex align-items-center mt-2 mb-5">
            <label className="m-2 cursor-pointer" htmlFor="settings_lang">
              {t("settings.profile_lang")}
            </label>
            <Select
              defaultValue={user.lang}
              className="text-uppercase"
              name="lang"
              options={languageOptions.map((o) => {
                return { value: o, text: o };
              })}
            />
          </div>

          <div className="d-flex align-items-center">
            <Checkbox
              className="m-2 cursor-pointer"
              id="settings_newsletter_email"
              name="newsletter_email"
              checked={user.newsletterEmail}
            />
            <label
              className="m-2 cursor-pointer"
              htmlFor="settings_newsletter_email"
            >
              {t("settings.register_newsletter")}
            </label>
          </div>

          <div className="d-flex align-items-center">
            <Checkbox
              className="m-2 cursor-pointer"
              id="settings_info_email"
              name="info_email"
              checked={user.infoEmail}
            />
            <label className="m-2 cursor-pointer" htmlFor="settings_info_email">
              {t("settings.register_info_email")}
            </label>
          </div>

          <div className="d-flex align-items-center">
            <Checkbox
              className="m-2 cursor-pointer"
              id="settings_user_type"
              checked={user.userType === USER_TYPE.COMPANY}
              onChange={(e) => {
                e.target.checked
                  ? setMode(USER_TYPE.COMPANY)
                  : setMode(USER_TYPE.DEFAULT);
              }}
            />
            <label className="m-2 cursor-pointer" htmlFor="settings_user_type">
              {t("settings.user-type-company")}
            </label>
          </div>

          <Button
            className="mt-4"
            loading={isLocaleLoading}
            type="submit"
            text={isSaveSuccess ? t("settings.saved") : t("settings.save")}
          />
        </form>
      </div>
    </>
  );
}
