import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

interface Props {
  light?: boolean;
  center?: boolean;
}

export default function Links({ light, center }: Props) {
  const { t } = useTranslation();

  const navlinks: Array<{
    name: string;
    uri: string;
    hidden?: boolean;
    normal?: boolean;
  }> = [
    {
      name: t("links.home"),
      uri: "/",
    },
    {
      name: t("links.contact"),
      uri: "mailto:info@memoramea.com",
      normal: true,
    },
    {
      name: t("links.support"),
      uri: "mailto:info@memoramea.com",
      normal: true,
    },
    {
      name: t("links.terms_privacy"),
      uri: "terms-privacy",
    },
  ];

  return (
    <div className={`d-flex d-md-block flex-column align-items-center`}>
      {navlinks
        .filter((l) => !l.hidden)
        .map((l) => (
          <div key={l.name + ":" + l.uri}>
            {l.normal ? (
              <a href={l.uri} className={`nav-link ${light ? "light" : ""}`}>
                {l.name}
              </a>
            ) : (
              <Link
                className={`nav-link ${light ? "light" : ""}`}
                key={l.name + ":" + l.uri}
                to={l.uri}
              >
                {l.name}
              </Link>
            )}
          </div>
        ))}
    </div>
  );
}
