interface Props {
  onChange?: Function;
  defaultValue?: string | number;
  options: Array<{ value: string | number; text: string | number }>;
  className?: string;
  name?: string;
  id?: string;
  small?: boolean;
  value?: string | number;
}

export default function Select(props: Props) {
  return (
    <select
      value={props.value}
      id={props.id}
      className={`custom-select ${props.className} ${
        props.small ? "small" : ""
      }`}
      defaultValue={props.defaultValue}
      onChange={(e) => props.onChange?.(e)}
      name={props.name}
    >
      {props.options.map((o) => (
        <option key={"select_" + o.value} value={o.value}>
          {o.text}
        </option>
      ))}
    </select>
  );
}
