import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { verifyEmail } from "../services/auth";
import { useAppDispatch } from "../services/redux";
import { setIsLoading } from "../store/appSlice";
import { setUser } from "../store/userSlice";

export default function VerifyEmailToken() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const _verifyEmailToken = async () => {
    dispatch(setIsLoading(true));
    let params = new URL(window.location as any).searchParams;
    let token = params.get("token");

    try {
      if (!token) throw new Error("Token not set");

      const user = await verifyEmail(token);
      dispatch(setUser(user));
    } catch (error) {
      alert(error);
    }

    navigate("/");
    dispatch(setIsLoading(false));
  };

  useEffect(() => {
    _verifyEmailToken();
  });

  return <></>;
}
