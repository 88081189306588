import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
// import Comments from "../components/Comments";
import {
  AVATAR_DEFAULT,
  COVER_DEFAULT,
  getUserProfile,
  isLoggedIn,
  iUser,
} from "../services/auth";
import {
  addLikeBacheca,
  getMyBacheca,
  iBacheca,
  removeLikeBacheca,
  APPROVAL_RULE,
  BACHECA_PAY_STATUS,
} from "../services/bacheca";
import { datetimeToString } from "../utils/generic";
import { useTranslation } from "react-i18next";
// import Likes from "../components/generic/Likes";
import Button from "../components/dom/Button";
import ContentDrop from "../components/generic/ContentDrop";
import { addLocalLike, removeLocalLike, userLikes } from "../services/like";
import ShareBox from "../components/generic/ShareBox";
import Loader from "../components/Loader";
import { useSelector } from "react-redux";
import { selectUser } from "../store/userSlice";
import { useAppDispatch } from "../services/redux";
import { setIsCheckoutModalOpen } from "../store/appSlice";
import shareIcon from "../assets/svg/share.svg";
import { openModal } from "../utils/modal";

type loadingType = "creator" | "bacheca" | "contents" | "generic" | "like";

export default function BachecaDetail() {
  const [bacheca, setBacheca] = useState<iBacheca>();
  const [creator, setCreator] = useState<iUser>();
  const [loadingArray, setLoadingArray] = useState<Array<loadingType>>([]);
  const { id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isBioExpanded, setIsBioExpanded] = useState(false);

  const user = useSelector(selectUser);
  const dispatch = useAppDispatch();

  const setLoading = (e: loadingType) => {
    setLoadingArray((loadingArray) => {
      const a = loadingArray;
      a.push(e);
      return a;
    });
  };
  const removeLoading = (e: loadingType) => {
    setLoadingArray((loadingArray) => {
      const a = loadingArray.filter((l) => l !== e);
      return a;
    });
  };

  const _loadBacheca = async () => {
    if (!id) return;

    setLoading("bacheca");
    setLoading("creator");

    try {
      const data = await getMyBacheca(id!);
      setBacheca(data);

      const datacreator = await getUserProfile(String(data.user));
      setCreator(datacreator);
    } catch (error) {
      console.log(error);
    }

    removeLoading("creator");
    removeLoading("bacheca");
  };

  useEffect(() => {
    _loadBacheca();
    // eslint-disable-next-line
  }, [id]);

  const likeButton = async () => {
    if (!isLoggedIn()) return;
    setLoading("like");

    try {
      await addLikeBacheca(bacheca!._id);
      setBacheca((b) => {
        if (!b) return;
        b.likeAmount++;
        return b;
      });

      addLocalLike(bacheca!._id);
    } catch (error) {
      console.log(error);
    }

    removeLoading("like");
  };

  const unlikeButton = async () => {
    if (!isLoggedIn()) return;
    setLoading("like");

    try {
      await removeLikeBacheca(bacheca!._id);
      setBacheca((b) => {
        if (!b) return;
        b.likeAmount--;
        return b;
      });

      removeLocalLike(bacheca!._id);
    } catch (error) {
      console.log(error);
    }

    removeLoading("like");
  };

  if (!bacheca)
    return (
      <div className="d-flex justify-content-center align-items-center">
        <Loader />
      </div>
    );

  return (
    <main className="bacheca-detail">
      {bacheca.user === user._id ? (
        <div className="d-flex gap-2 w100 my-3">
          {bacheca.payStatus === BACHECA_PAY_STATUS.TO_PAY &&
          bacheca.user === user._id ? (
            <Button
              error
              onClick={() =>
                dispatch(
                  setIsCheckoutModalOpen({
                    bacheca: bacheca,
                    callback: () => window.location.reload(),
                  })
                )
              }
              small
              text={t("board_card.pay")}
            />
          ) : null}
          {bacheca.user === user._id ? (
            <Button
              onClick={() => navigate("/bacheca/edit/" + bacheca._id)}
              small
              text={t("board.edit")}
            />
          ) : null}
        </div>
      ) : null}

      <div className="container">
        <div className="content">
          <div className="gap-3 w100">
            <div className="d-flex justify-content-end w100">
              <div className="share">
                <img
                  style={{ height: "25px", cursor: "pointer" }}
                  className="m-1"
                  src={shareIcon}
                  onClick={() => {
                    openModal({
                      content: (
                        <ShareBox
                          id={"share" + bacheca._id}
                          link={`${process.env.REACT_APP_FRONTEND_URI}/bacheca/${bacheca._id}`}
                        />
                      ),
                    });
                  }}
                  alt=""
                />
              </div>
            </div>
            <div className="bacheca mb-5 w100">
              <div className="gap-4 w100">
                <div
                  style={{
                    backgroundImage: `url(${
                      bacheca.coverImage ?? COVER_DEFAULT
                    })`,
                    backgroundSize: "cover",
                    backgroundPosition: "center center",
                  }}
                  className="cover py-5"
                >
                  <div
                    style={{
                      backgroundImage: `url(${
                        bacheca.profileImage ?? AVATAR_DEFAULT
                      })`,
                      backgroundSize: "cover",
                      backgroundPosition: "center center",
                    }}
                    className="profile"
                  ></div>
                </div>
                <div className="details">
                  <div className="info">
                    <p className="name h1 mb-0">{bacheca.name}</p>
                    <p className="birth-death text-muted">
                      <i className="d-none d-xl-block">
                        {datetimeToString(bacheca.birthTime)}{" "}
                        {bacheca.birthCity} -{" "}
                        {datetimeToString(bacheca.deathTime)}{" "}
                        {bacheca.deathCity}
                      </i>
                      <i className="d-xl-none">
                        {datetimeToString(bacheca.birthTime, true)}{" "}
                        {bacheca.birthCity} -{" "}
                        {datetimeToString(bacheca.deathTime, true)}{" "}
                        {bacheca.deathCity}
                      </i>
                    </p>
                    {bacheca.bio.length > 250 ? (
                      isBioExpanded ? (
                        <p className="bio">
                          {bacheca.bio}{" "}
                          <span
                            onClick={() => setIsBioExpanded(false)}
                            className="cursor-pointer"
                          >
                            <strong>{t("board.read_less")}</strong>
                          </span>{" "}
                        </p>
                      ) : (
                        <p className="bio">
                          {bacheca.bio.substring(0, 250) + "..."}{" "}
                          <span
                            onClick={() => setIsBioExpanded(true)}
                            className="cursor-pointer"
                          >
                            <strong>{t("board.read_more")}</strong>
                          </span>{" "}
                        </p>
                      )
                    ) : (
                      <p className="bio">{bacheca.bio}</p>
                    )}
                  </div>
                  <div className="creator mt-5">
                    <div
                      style={{
                        backgroundImage: `url(${
                          creator?.profileImage ?? AVATAR_DEFAULT
                        })`,
                        backgroundSize: "cover",
                        backgroundPosition: "center center",
                      }}
                      className="image"
                    ></div>
                    <div>
                      <p className="m-0 text-muted">
                        <i>{t("board.created_by")}</i>
                      </p>

                      <p
                        className="h6 cursor-pointer m-0 mb-3"
                        onClick={() => navigate("/channel/" + creator?._id)}
                      >
                        {creator?.name}
                      </p>
                    </div>
                  </div>
                  {/* TODO PUT LIKES BACK */}
                  {/* <div className="d-flex justify-content-end">
                <Likes
                  number={bacheca.likeAmount}
                  active={userLikes(bacheca._id)}
                  onClick={() => {
                    if (loadingArray.length > 0) return;
                    if (userLikes(bacheca._id)) unlikeButton();
                    else likeButton();
                  }}
                />
              </div> */}
                </div>
              </div>
              {/* TODO PUT COMMENTS BACK */}
              {/* <div className="mb-4">
          {bacheca.payStatus === BACHECA_PAY_STATUS.PAID && bacheca?._id ? (
            <Comments
              editors={[
                ...(bacheca.editors as Array<string>),
                String(bacheca.user),
              ]}
              bacheca_id={bacheca._id}
            />
          ) : null}
        </div> */}
            </div>
          </div>
        </div>
      </div>

      {bacheca.payStatus === BACHECA_PAY_STATUS.PAID &&
      bacheca.approvalContentDefault !== APPROVAL_RULE.DENIED ? (
        <Button
          text={t("board.create_memory")}
          onClick={() => {
            navigate(`/bacheca/${bacheca._id}/content/create`);
          }}
        />
      ) : null}
      {bacheca.payStatus === BACHECA_PAY_STATUS.PAID ? (
        <ContentDrop
          editors={[...bacheca.editors, bacheca.user]}
          className="mt-5"
        />
      ) : null}
    </main>
  );
}
