import { iBacheca } from "../../services/bacheca";
import BachecaCard from "./BachecaCard";
import { useTranslation } from "react-i18next";

interface Props {
  bachecas: Array<iBacheca>;
  onUpdate: Function;
  className?: string;
}

export default function BachecaList({ bachecas, onUpdate, className }: Props) {
  const { t } = useTranslation();

  return (
    <div
      className={`results-container w100 d-flex flex-column gap-5 gap-md-3 ${className}`}
    >
      {bachecas.map((r, key) => (
        <BachecaCard onUpdate={onUpdate} key={"result_" + key} bacheca={r} />
      ))}
      {bachecas.length === 0 ? (
        <span className="w100 text-center mt-5">{t("search.no_results")}</span>
      ) : null}
    </div>
  );
}
