interface Props {
  light?: boolean;
  small?: boolean;
  scale?: number;
}

export default function Logo({ light, small, scale }: Props) {
  return (
    <div
      className={`logo ${light ? "light" : ""}`}
      style={{ transform: `scale(${scale ?? 1})` }}
    >
      <span>{small ? "M" : "Memora"}</span>
      <span>{small ? "M" : "Mea"}</span>
    </div>
  );
}
