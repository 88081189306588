import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../../components/dom/Button";
import CustomDatePicker from "../../components/dom/CustomDatePicker";
import Select from "../../components/dom/Select";
import Stepper from "../../components/generic/Stepper";
import Loader from "../../components/Loader";
import { AVATAR_DEFAULT, COVER_DEFAULT, isLoggedIn } from "../../services/auth";
import {
  APPROVAL_RULE,
  createBacheca,
  getMyBacheca,
  iBacheca,
  patchBacheca,
} from "../../services/bacheca";
import { selectUser } from "../../store/userSlice";
// import { FONTS } from "../../utils/fonts";
import {
  autoGrowTextArea,
  compressImage,
  datetimeToString,
  formToObject,
} from "../../utils/generic";

export default function BachecaForm() {
  const { t } = useTranslation();
  const STEPS = ["step_basic", "step_images", "step_settings", "step_preview"];
  const tSTEPS = STEPS.map((s) => t("board_form." + s));

  const user = useSelector(selectUser);

  const lorem20 =
    "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Consequatur reprehenderit sed distinctio laborum tempore delectus minus, doloremque quo, commodi maiores odio reiciendis vero repellat cum assumenda rem dicta voluptas quos saepe ipsum, provident cupiditate sapiente ad. Est beatae sint numquam.";

  const [bacheca, setBacheca] = useState<iBacheca>();
  const [bacheca_id, setBachecaId] = useState("");
  const [step, setStep] = useState(0);
  const { id } = useParams();
  const isLogged = isLoggedIn();

  const [isLocaleLoading, setIsLocaleLoading] = useState(false);

  const [isProfileImageUploading, setIsProfileImageUploading] = useState(false);
  const [isCoverImageUploading, setIsCoverImageUploading] = useState(false);
  const [bachecaPreview, setBachecaPreview] = useState<any>({});

  const navigate = useNavigate();

  const _initBacheca = async () => {
    if (!id) return;
    if (id) setBachecaId(id);

    setIsLocaleLoading(true);

    try {
      const data = await getMyBacheca(id!);
      setBacheca(data);
    } catch (error) {
      console.log(error);
    }

    setIsLocaleLoading(false);
  };

  useEffect(() => {
    _initBacheca();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const bachecaForm = async (e: any) => {
    setIsLocaleLoading(true);

    e.preventDefault();

    try {
      const payload = formToObject(e.target);

      let bachecaRes;
      if (payload.bacheca_id) bachecaRes = await patchBacheca(payload);
      else bachecaRes = await createBacheca(payload);

      setBacheca(bachecaRes);
      setBachecaId(bachecaRes._id);
    } catch (error) {
      alert(error);
    }

    setIsLocaleLoading(false);
    navigate("/channel/" + user._id);
  };

  const stepClick = (direction: "back" | "forwards") => {
    if (direction === "back" && step > 0) {
      setStep(step - 1);
    }

    if (direction === "forwards" && step < STEPS.length - 1) {
      setStep(step + 1);
    }

    initBachecaPreview();
  };

  const profileImageInput = (e: any) => {
    try {
      const image = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onloadend = async () => {
        try {
          setIsProfileImageUploading(true);
          const compressedImage = await compressImage(String(reader.result));
          (document.getElementById("profile_image_raw") as any).value =
            compressedImage;
          initBachecaPreview();
        } catch (error) {}
        setIsProfileImageUploading(false);
      };
    } catch (error) {}
  };

  const coverImageInput = (e: any) => {
    try {
      const image = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onloadend = async () => {
        try {
          setIsCoverImageUploading(true);
          const compressedImage = await compressImage(String(reader.result));
          (document.getElementById("cover_image_raw") as any).value =
            compressedImage;
          initBachecaPreview();
        } catch (error) {}
        setIsCoverImageUploading(false);
      };
    } catch (error) {}
  };

  const initBachecaPreview = () => {
    const form = document.getElementById("bacheca-form");
    const data = formToObject(form, false);
    setBachecaPreview(data);
  };

  // if (!isLogged)
  //   return (
  //     <main className="container">
  //       <div className="w100 mt-3 d-flex flex-column align-items-center">
  //         <span>{t("board_form.no_login_error")}</span>
  //         <Button
  //           onClick={() => {
  //             // todo: change this with navigate to avoid reload.
  //             window.location.href = "/profile";
  //           }}
  //           text={t("auth.login")}
  //           className="mt-4"
  //         />
  //       </div>
  //     </main>
  //   );

  // if (!user.emailVerified)
  //   return (
  //     <main className="container">
  //       <div className="w100 mt-3 d-flex flex-column align-items-center">
  //         <span>{t("board_form.no_verified_error")}</span>
  //         <Button
  //           onClick={() => {
  //             // todo: change this with navigate to avoid reload.
  //             window.location.href = "/profile";
  //           }}
  //           text={t("auth.profile")}
  //           className="mt-4"
  //         />
  //       </div>
  //     </main>
  //   );

  const pageButtons = (
    <div className="d-flex w100 mt-5 mx-2 px-3">
      {step > 0 ? (
        <Button
          secondary
          small
          onClick={() => stepClick("back")}
          text={t("board_form.back")}
          className="mr-auto"
        />
      ) : null}
      {step < STEPS.length - 1 ? (
        <Button
          className="ml-auto"
          small
          onClick={() => stepClick("forwards")}
          text={t("board_form.next")}
        />
      ) : null}
      {step === STEPS.length - 1 ? (
        <Button
          loading={isLocaleLoading}
          className="save-button ml-auto"
          type="submit"
          text={bacheca_id ? t("board_form.save") : t("board_form.create")}
        />
      ) : null}
    </div>
  );

  const stepper = (
    <Stepper
      steps={tSTEPS}
      onChange={(s: number) => {
        setStep(s);
        initBachecaPreview();
      }}
      currentStep={step}
      className="mt-5 mx-2"
    />
  );

  const stepBasic = (
    <div className={`step ${STEPS[step] === "step_basic" ? "active" : ""}`}>
      <label htmlFor="form-name">{t("board_form.name")}</label>
      <input
        required
        id="form-name"
        type="text"
        name="name"
        defaultValue={bacheca?.name}
      />
      <label htmlFor="form-bio">{t("board_form.bio")}</label>
      <textarea
        required
        onInput={autoGrowTextArea}
        id="form-bio"
        name="bio"
        defaultValue={bacheca?.bio}
      />
      <div className="row">
        <div className="col">
          <div className="row">
            <div className="col">
              <label htmlFor="form-birth-time">
                {t("board_form.birth_time")}
              </label>
              <CustomDatePicker
                defaultValue={bacheca?.birthTime}
                name="birth_time"
              />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <label htmlFor="form-birth-city">
                {t("board_form.birth_city")}
              </label>
              <input
                id="form-birth-city"
                required
                type="text"
                name="birth_city"
                defaultValue={bacheca?.birthCity}
              />
            </div>
          </div>
        </div>
        <div className="col">
          <div className="row">
            <div className="col">
              <label htmlFor="form-death-time">
                {t("board_form.death_time")}
              </label>
              <CustomDatePicker
                defaultValue={bacheca?.deathTime}
                name="death_time"
              />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <label htmlFor="form-death-city">
                {t("board_form.death_city")}
              </label>
              <input
                id="form-death-city"
                required
                type="text"
                name="death_city"
                defaultValue={bacheca?.deathCity}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  const stepImages = (
    <div
      className={`d-flex align-items-center step ${
        STEPS[step] === "step_images" ? "active" : ""
      }`}
    >
      <input
        defaultValue={bacheca?.profileImage}
        hidden
        type="text"
        name="profile_image"
        id="profile_image_raw"
      />
      <input
        defaultValue={bacheca?.coverImage}
        hidden
        type="text"
        name="cover_image"
        id="cover_image_raw"
      />

      <span className="h5">{t("board_form.profile_image")}</span>
      <div className="profile-img-container">
        <input
          onChange={profileImageInput}
          type="file"
          id="settings-profile-image"
          accept="image/png, image/jpeg"
        />
        {isProfileImageUploading ? (
          <div className="profile-image-loading">
            <Loader light />
          </div>
        ) : null}
        <div
          onClick={() =>
            document.getElementById("settings-profile-image")?.click()
          }
          className="avatar"
          data-text={t("board_form.choose_image")}
          style={{
            backgroundImage: `url(${
              bachecaPreview?.profile_image ??
              bacheca?.profileImage ??
              AVATAR_DEFAULT
            })`,
            backgroundPosition: "center center",
            backgroundSize: "cover",
          }}
        ></div>
      </div>
      <span className="h5 mt-5">{t("board_form.cover_image")}</span>

      <div className="cover-img-container">
        <input
          onChange={coverImageInput}
          type="file"
          id="settings-cover-image"
          accept="image/png, image/jpeg"
        />

        <div
          onClick={() =>
            document.getElementById("settings-cover-image")?.click()
          }
          className="cover"
          data-text={t("board_form.choose_image")}
          style={{
            backgroundImage: `url(${
              bachecaPreview.cover_image ?? bacheca?.coverImage ?? COVER_DEFAULT
            })`,
            backgroundPosition: "center center",
            backgroundSize: "cover",
          }}
        >
          {isCoverImageUploading ? (
            <div className="cover-image-loading">
              <Loader light />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );

  const stepSettings = (
    <div className={`step ${STEPS[step] === "step_settings" ? "active" : ""}`}>
      {/* <span className="h5">{t("appareance")}</span>
      <div className="d-flex gap-5 align-items-center">
        <div className="d-flex gap-3 align-items-center">
          <label htmlFor="form-font">{t("font")}</label>
          <Select
            id="form-font"
            name="theme_font"
            options={Object.keys(FONTS).map((k) => {
              return { text: k, value: k };
            })}
          />
        </div>
        <div className="d-flex gap-3 align-items-center">
          <label htmlFor="form-color">{t("color")}</label>
          <input
            className="m-0"
            type="color"
            name="theme_color"
            id="form-color"
          />
        </div>
      </div> */}

      <div className="d-flex gap-3 align-items-center">
        <label htmlFor="form-approval">{t("board_form.approval_policy")}</label>
        <Select
          name="approval_content_default"
          id="form-approval"
          options={Object.keys(APPROVAL_RULE).map((k) => {
            return { text: String(t("board_form.rule_" + k)), value: k };
          })}
        />
      </div>
      <div className="mt-3">
        <p>
          <u>{t("board_form.rule_" + APPROVAL_RULE.APPROVED)}: </u>
          {t("board_form.rule_detail_" + APPROVAL_RULE.APPROVED)}
        </p>
        <p>
          <u>{t("board_form.rule_" + APPROVAL_RULE.TO_APPROVE)}: </u>
          {t("board_form.rule_detail_" + APPROVAL_RULE.TO_APPROVE)}
        </p>
        <p>
          <u>{t("board_form.rule_" + APPROVAL_RULE.DENIED)}: </u>
          {t("board_form.rule_detail_" + APPROVAL_RULE.DENIED)}
        </p>
      </div>
    </div>
  );

  const step_preview = (
    <div className={`step ${STEPS[step] === "step_preview" ? "active" : ""}`}>
      <div className="preview">
        <div
          className="preview-cover-image"
          style={{
            backgroundImage: `url(${
              bachecaPreview.cover_image ?? COVER_DEFAULT
            })`,
            backgroundPosition: "center center",
            backgroundSize: "cover",
          }}
        ></div>
        <div
          className="preview-profile-image"
          style={{
            backgroundImage: `url(${
              bachecaPreview.profile_image ?? AVATAR_DEFAULT
            })`,
            backgroundPosition: "center center",
            backgroundSize: "cover",
          }}
        ></div>
        <span className="h3 mt-4 mb-0">
          {bachecaPreview.name ?? t("board_form.full_name")}
        </span>
        <p className="text-muted text-center">
          <i>
            <span className="mx-1">
              {datetimeToString(bachecaPreview.birth_time) ??
                `${t("board_form.birth_time")}`}
            </span>
            <span className="mx-1">
              {bachecaPreview.birth_city ?? `${t("board_form.birth_city")}`}
            </span>
            <span className="mx-1">-</span>
            <span className="mx-1">
              {datetimeToString(bachecaPreview.death_time) ??
                `${t("board_form.death_time")}`}
            </span>
            <span className="mx-1">
              {bachecaPreview.death_city ?? `${t("board_form.death_city")}`}
            </span>
          </i>
        </p>
        <p className="h6 text-center mb-4 mt-2">
          {bachecaPreview.bio ?? lorem20}
        </p>
      </div>
    </div>
  );

  return (
    <main className="container bacheca-form">
      {stepper}
      <div className="hr light"></div>
      <form id="bacheca-form" onSubmit={bachecaForm} className="mx-2">
        <input hidden type="text" name="bacheca_id" defaultValue={bacheca_id} />

        {stepBasic}
        {stepImages}
        {stepSettings}
        {step_preview}
        {pageButtons}
      </form>
    </main>
  );
}
