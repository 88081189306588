import { t } from "i18next";

export function formToObject(form: any, includeVoid = true) {
  const myFormData = new FormData(form);

  const formDataObj: any = {};
  myFormData.forEach((value, key) => {
    if (!includeVoid && value === "") return;

    if (key.includes("[]")) {
      key = String(key).replace("[]", "");
      if (!formDataObj[key]) formDataObj[key] = [];
      formDataObj[key].push(value);
      return;
    }

    if (key.includes("->")) {
      const firstLevelKey = String(key).split("->")[0];
      const secondLevelKey = String(key).split("->")[1];

      formDataObj[firstLevelKey] = {
        [secondLevelKey]: value,
        ...(formDataObj[firstLevelKey] ?? {}),
      };

      return;
    }

    formDataObj[key] = value;
  });

  return formDataObj;
}

export const autoGrowTextArea = (e: any) => {
  e.target.style.height = "5px";
  e.target.style.height = e.target.scrollHeight + "px";
};

export const datetimeToString = (isodate: any, short = false) => {
  if (!isodate) return undefined;

  const months = [
    t("january"),
    t("february"),
    t("march"),
    t("april"),
    t("may"),
    t("june"),
    t("july"),
    t("august"),
    t("september"),
    t("october"),
    t("november"),
    t("december"),
  ];

  const date = new Date(isodate);

  if (short)
    return (
      date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear()
    );

  return (
    date.getDate() + " " + months[date.getMonth()] + " " + date.getFullYear()
  );
};

export function compressImage(dataUrl: string, newWidth: number = 1024) {
  return new Promise((resolve) => {
    let image: any, oldWidth, oldHeight, newHeight, canvas;

    // Create a temporary image so that we can compute the height of the downscaled image.
    image = new Image();
    image.onload = function () {
      oldWidth = image.width;
      oldHeight = image.height;
      newHeight = Math.floor((oldHeight / oldWidth) * newWidth);

      // Create a temporary canvas to draw the downscaled image on.
      canvas = document.createElement("canvas");
      canvas.width = newWidth;
      canvas.height = newHeight;

      // Draw the downscaled image on the canvas and resolve the promise with the new data URL.
      canvas.getContext("2d")!.drawImage(image, 0, 0, newWidth, newHeight);
      resolve(canvas.toDataURL());
    };
    image.src = dataUrl;
  });
}

export function removeSpecialCharacters(str: string): string {
  return str.replace(/[^a-zA-Z0-9]/g, "");
}
