import axios from "axios";
import { FONTS } from "../utils/fonts";
import { iUser } from "./auth";
import { loadUserLikes } from "./like";

export enum BACHECA_PAY_STATUS {
  TO_PAY = "TO_PAY",
  PAID = "PAID",
}

export enum APPROVAL_RULE {
  APPROVED = "APPROVED",
  TO_APPROVE = "TO_APPROVE",
  DENIED = "DENIED",
}

export interface iBacheca {
  _id: string;
  name: string;
  bio: string;
  deathTime: Date;
  birthTime: Date;
  deathCity: string;
  birthCity: string;
  coverImage: string;
  profileImage: string;
  themeFont: FONTS;
  themeColor: string;
  user: iUser | string;
  editors: Array<iUser | string>;
  approvalContentDefault: APPROVAL_RULE;
  payStatus: BACHECA_PAY_STATUS;
  hidden: boolean;
  createdAt: any;
  updatedAt: any;
  likeAmount: number;
}

export async function getMyBachecas(): Promise<Array<iBacheca>> {
  const response = await axios.get("/bacheca/edit");

  await loadUserLikes({
    bacheca_ids: (response.data as Array<iBacheca>).map((e) => e._id),
  });

  return response.data;
}

export async function getMyBacheca(id: string): Promise<iBacheca> {
  const response = await axios.get("/bacheca/" + id);

  await loadUserLikes({
    bacheca_ids: [(response.data as iBacheca)._id],
  });

  return response.data;
}

export async function createBacheca(payload: any): Promise<iBacheca> {
  const response = await axios.post("/bacheca/edit", payload);
  return response.data;
}

export async function patchBacheca(payload: any): Promise<iBacheca> {
  const response = await axios.patch("/bacheca/edit", payload);
  return response.data;
}

export async function deleteBacheca(id: string): Promise<any> {
  const response = await axios.delete("/bacheca/edit", {
    data: {
      bacheca_id: id,
    },
  });
  return response.data;
}

export async function addLikeBacheca(id: string): Promise<any> {
  const response = await axios.post("/like", {
    bacheca_id: id,
  });
  return response.data;
}

export async function removeLikeBacheca(id: string): Promise<any> {
  const response = await axios.delete("/like", {
    data: {
      bacheca_id: id,
    },
  });
  return response.data;
}

export async function changeBachecaOwner(payload: any): Promise<iBacheca> {
  const response = await axios.patch("/bacheca/edit/owner", payload);
  return response.data;
}

export async function addBachecaEditor(payload: any): Promise<iBacheca> {
  const response = await axios.post("/bacheca/edit/editor", payload);
  return response.data;
}

export async function removeBachecaEditor(payload: any): Promise<iBacheca> {
  const response = await axios.delete("/bacheca/edit/editor", {
    data: payload,
  });
  return response.data;
}

export async function searchBachecasByName(
  name: string,
  page: number = 1
): Promise<Array<iBacheca>> {
  const response = await axios.get(`/bacheca/search?name=${name}&page=${page}`);
  return response.data;
}

export async function getBachecasOfChannel(id: string, page: number = 1) {
  const response = await axios.get(`/bacheca/user/${id}?page=${page}`);

  await loadUserLikes({
    bacheca_ids: (response.data as Array<iBacheca>).map((e) => e._id),
  });

  return response.data;
}

export async function getEditorsOfBacheca(id: string): Promise<Array<iUser>> {
  const response = await axios.get(`/bacheca/edit/editor/${id}`);
  return response.data;
}
