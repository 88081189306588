import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { changeBachecaOwner } from "../../services/bacheca";
import { useAppDispatch } from "../../services/redux";
import {
  selectBachecaTransferModalOpen,
  setIsBachecaSettingsModalOpen,
  setIsBachecaTransferModalOpen,
} from "../../store/appSlice";
import { formToObject } from "../../utils/generic";
import Button from "../dom/Button";

export default function BachecaTransferModal() {
  const data = useSelector(selectBachecaTransferModalOpen);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [isLocaleLoading, setIsLocaleLoading] = useState(false);
  const [error, setError] = useState("");

  const changeOwnerForm = async (e: any) => {
    e.preventDefault();
    setError("");
    if (!e.target.user_email.value) return;

    setIsLocaleLoading(true);

    try {
      const payload = formToObject(e.target);
      await changeBachecaOwner(payload);
    } catch (error: any) {
      setError(error?.response?.data?.message);
      console.log(error);
    }

    setIsLocaleLoading(false);
    dispatch(setIsBachecaTransferModalOpen(undefined));
    data?.callback();
  };

  if (!data) return <></>;

  return (
    <div
      className="custom-modal-container"
      onClick={() => dispatch(setIsBachecaTransferModalOpen(undefined))}
    >
      <div onClick={(e) => e.stopPropagation()} className="custom-modal">
        <p className="mt-5 mb-4 h4">
          {t("transfer")} {data.bacheca.name}
        </p>

        <form
          className="mt-4 d-flex flex-row align-items-center gap-2"
          onSubmit={changeOwnerForm}
        >
          <input
            type="text"
            name="bacheca_id"
            hidden
            readOnly
            value={data.bacheca._id}
          />
          <input
            type="text"
            id="user_email"
            name="user_email"
            className="m-0 w100"
          />
          <Button
            loading={isLocaleLoading}
            small
            type="submit"
            text={t("change")}
          />
        </form>

        <span className="text-danger">{error}</span>

        <div
          onClick={() => dispatch(setIsBachecaSettingsModalOpen(undefined))}
          className="close"
        >
          X
        </div>

        <div className="actions">
          {/* <Button
            onClick={() => {
              settingsBachecaButton(data.bacheca._id);
            }}
            loading={isLocaleLoading}
            light
            text={t("save")}
          /> */}
        </div>
      </div>
    </div>
  );
}
