import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "./dom/Button";

const COOKIES_ACCEPTED_KEY = "COOKIES_ACCEPTED_2";

export default function CookiesBanner() {
  const { t } = useTranslation();
  const [cookiesAccepted, setCookiesAccepted] = useState(true);

  useEffect(() => {
    setCookiesAccepted(
      window.localStorage.getItem(COOKIES_ACCEPTED_KEY) ? true : false
    );
  }, []);

  if (cookiesAccepted) return <></>;

  return (
    <div className="cookies-banner d-flex align-items-center justify-content-center gap-4">
      <span>{t("cookies.text")}</span>

      <Button
        text="OK"
        light
        small
        onClick={() => {
          window.localStorage.setItem(COOKIES_ACCEPTED_KEY, "true");
          setCookiesAccepted(true);
        }}
      />
    </div>
  );
}
