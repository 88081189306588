import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { deleteComment } from "../../services/comment";
import { useAppDispatch } from "../../services/redux";
import {
  selectCommentDeleteModalOpen,
  setIsCommentDeleteModalOpen,
} from "../../store/appSlice";
import Button from "../dom/Button";

export default function CommentDeleteModal() {
  const data = useSelector(selectCommentDeleteModalOpen);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [isLocaleLoading, setIsLocaleLoading] = useState(false);

  if (!data) return <></>;

  const deleteCommentButton = async (id: string) => {
    setIsLocaleLoading(true);
    try {
      await deleteComment(id);
      data.callback();
      dispatch(setIsCommentDeleteModalOpen(undefined));
    } catch (error) {
      alert(error);
    }

    setIsLocaleLoading(false);
  };

  return (
    <div
      className="custom-modal-container"
      onClick={() => dispatch(setIsCommentDeleteModalOpen(undefined))}
    >
      <div className="custom-modal">
        <p className="my-5">{t("settings.confirm_delete")}</p>

        <div
          onClick={() => dispatch(setIsCommentDeleteModalOpen(undefined))}
          className="close"
        >
          X
        </div>
        <div className="actions">
          <Button
            onClick={() => deleteCommentButton(data.comment._id)}
            loading={isLocaleLoading}
            error
            text={t("settings.delete")}
          />
        </div>
      </div>
    </div>
  );
}
