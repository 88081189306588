import HomeAbout from "../components/home/HomeAbout";
import HomeHeader from "../components/home/HomeHeader";
import HomeHow from "../components/home/HomeHow";
import HomeQuote from "../components/home/HomeQuote";
// import { useSelector } from "react-redux";
// import { selectLoading } from "../store/appSlice";
// import { useAppDispatch } from "../services/redux";
// import { useTranslation } from "react-i18next";
// import i18next from "i18next";

export default function Home() {
  // const isLoading = useSelector(selectLoading);
  // const dispatch = useAppDispatch();
  // const { t } = useTranslation();

  return (
    <main className="home">
      <HomeHeader />
      <div className="divider"></div>
      <HomeAbout />
      <div className="divider"></div>
      <HomeQuote />
      <div className="divider"></div>
      <HomeHow />
    </main>
  );
}
