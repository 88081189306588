import { useTranslation } from "react-i18next";

export default function HomeAbout() {
  const { t } = useTranslation();

  const data: Array<{
    title: string;
    text: string;
    bg?: string;
    light?: boolean;
  }> = [
    {
      title: t("home.about.1.title"),
      text: t("home.about.1.text"),
    },
    {
      title: t("home.about.2.title"),
      text: t("home.about.2.text"),
    },
    {
      title: t("home.about.3.title"),
      text: t("home.about.3.text"),
    },
    {
      title: t("home.about.4.title"),
      text: t("home.about.4.text"),
    },
    {
      title: t("home.about.5.title"),
      text: t("home.about.5.text"),
      bg: "black",
      light: true,
    },
    {
      title: t("home.about.6.title"),
      text: t("home.about.6.text"),
    },
  ];

  return (
    <section className="container home-about">
      <div className="d-flex justify-content-center">
        <h3 className="main-title mt-5"> {t("home.about.title")} </h3>
      </div>
      <div className="slot-container w100 d-flex flex-column align-items-center">
        {data.map((e, key) => {
          if (key % 2 !== 0) return null;
          return (
            <div key={"about_" + key} className="d-flex">
              <div
                className={`slot ${
                  data[key].bg ? "bg-" + data[key].bg : "bg-accent"
                }`}
              >
                <h4 className={`${data[key].light ? "text-light" : ""}`}>
                  {data[key].title}
                </h4>
                <p className={`${data[key].light ? "text-light" : ""}`}>
                  {data[key].text}
                </p>
              </div>
              {data[key + 1] ? (
                <div
                  className={`slot ${
                    data[key + 1].bg ? "bg-" + data[key + 1].bg : "bg-accent"
                  }`}
                >
                  <h4 className={`${data[key + 1].light ? "text-light" : ""}`}>
                    {data[key + 1].title}
                  </h4>
                  <p className={`${data[key + 1].light ? "text-light" : ""}`}>
                    {data[key + 1].text}
                  </p>
                </div>
              ) : null}
            </div>
          );
        })}
      </div>
    </section>
  );
}
