import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AVATAR_DEFAULT, isLoggedIn, logout } from "../services/auth";
import Logo from "./Logo";
import userSvg from "../assets/svg/user.svg";
import newSvg from "../assets/svg/new.svg";
import searchSvg from "../assets/svg/search.svg";
import bellSvg from "../assets/svg/bell.svg";
import menuSvg from "../assets/svg/menu.svg";
import closeSvg from "../assets/svg/close.svg";
import Searchbar from "./Nav/Searchbar";
import ProfileBox from "./Nav/ProfileBox";
import Auth from "./Auth";
import PasswordRecovery from "./PasswordRecovery";
import { useSelector } from "react-redux";
import {
  selectNotificationNumber,
  selectUser,
  setNotificationNumber,
} from "../store/userSlice";
import { useTranslation } from "react-i18next";
import Button from "./dom/Button";
import NotificationBox from "./Nav/NotificationBox";
import { resetNotificationCount } from "../services/notification";
import MenuBox from "./Nav/MenuBox";
import SettingsBox from "./Nav/SettingsBox";
import { useAppDispatch } from "../services/redux";
import PromoBox from "./Nav/PromoBox";

export default function Nav() {
  const isLogged = isLoggedIn();
  const navigate = useNavigate();
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [isShoulderOpen, setIsShoulderOpen] = useState(false);
  const [menuSelected, setMenuSelected] = useState<
    | undefined
    | "profile"
    | "notifications"
    | "forgot-password-email"
    | "menu"
    | "profile-settings"
    | "promo"
  >();
  const { t } = useTranslation();
  const notificationAmount = useSelector(selectNotificationNumber);
  const user = useSelector(selectUser);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (window.location.href.includes("password-recovery")) {
      setIsShoulderOpen(true);
      setMenuSelected("forgot-password-email");
    } else if (window.location.href.includes("profile")) {
      setIsShoulderOpen(true);
      setMenuSelected("profile");
    }
    // eslint-disable-next-line
  }, []);

  const forgotPasswordTab = () => {
    setMenuSelected("forgot-password-email");
  };

  return (
    <nav className="py-3 px-3 d-flex justify-content-between">
      <div className="d-flex gap-3 align-items-center">
        <div
          className="cursor-pointer"
          onClick={() => {
            navigate("/");
          }}
        >
          <div className="d-none d-md-block">
            <Logo scale={0.8} />
          </div>
          <div className="d-md-none">
            <Logo scale={0.8} small />
          </div>
        </div>
        <img
          className="d-block d-md-none search-icon cursor-pointer"
          src={searchSvg}
          alt="search-img"
          onClick={() => setIsSearchOpen(true)}
        />
      </div>

      <div
        className={`${
          isSearchOpen ? "search-overlay" : "d-none d-md-flex"
        } justify-content-center`}
      >
        <Searchbar />
        <img
          className={`${
            isSearchOpen ? "" : "d-md-none"
          }  close-search-icon cursor-pointer`}
          src={closeSvg}
          alt="close-icon"
          onClick={() => setIsSearchOpen(false)}
        />
      </div>

      <div className="gap-0 gap-sm-3 menu-container d-flex align-items-center justify-content-end">
        <div className="shoulder-icon new">
          <img
            src={newSvg}
            alt="new memora icon"
            className={`cursor-pointer menu-icon`}
            onClick={() => {
              setIsShoulderOpen(false);
              navigate("/bacheca/create");
            }}
          />
        </div>
        <div
          className={`shoulder-icon ${
            isShoulderOpen && menuSelected === "menu" ? "selected" : ""
          }`}
        >
          <img
            className={`cursor-pointer menu-icon`}
            src={menuSvg}
            alt="menu"
            onClick={() => {
              setIsShoulderOpen(true);
              setMenuSelected("menu");
            }}
          />
          <div className="inset"></div>
        </div>
        <div
          className={`shoulder-icon ${
            isShoulderOpen && menuSelected === "notifications" ? "selected" : ""
          }`}
        >
          {notificationAmount > 0 ? (
            <div className="notification-number">
              {notificationAmount > 99 ? "99+" : notificationAmount}
            </div>
          ) : null}
          <img
            className={`notifications-icon cursor-pointer`}
            src={bellSvg}
            alt="notifications"
            onClick={() => {
              resetNotificationCount();
              setIsShoulderOpen(true);
              setMenuSelected("notifications");
            }}
          />
          <div className="inset"></div>
        </div>
        <div
          className={`shoulder-icon ${
            isShoulderOpen &&
            [
              "profile",
              "forgot-password-email",
              "promo",
              "profile-settings",
            ].includes(String(menuSelected))
              ? "selected"
              : ""
          }`}
        >
          <img
            style={{
              height: "30px",
              width: "30px",
            }}
            className={`user-icon cursor-pointer rounded-circle`}
            src={
              !isShoulderOpen && isLogged
                ? user.profileImage ?? AVATAR_DEFAULT
                : userSvg
            }
            alt="user"
            onClick={() => {
              setIsShoulderOpen(true);
              setMenuSelected("profile");
            }}
          />
          <div className="inset"></div>
        </div>
      </div>
      {isShoulderOpen ? (
        <div
          className="shoulder-container"
          // onClick={() => setIsShoulderOpen(false)}
        >
          <div
            onClick={() => setIsShoulderOpen(false)}
            className="w100 h100"
          ></div>
          <div className="shoulder">
            <div className="close-search-icon-container">
              <img
                className="filter-invert close-search-icon cursor-pointer"
                src={closeSvg}
                alt="close-icon"
                onClick={() => setIsShoulderOpen(false)}
              />
            </div>
            <div className="shoulder-content">
              {menuSelected === "menu" ? (
                <MenuBox onClick={() => setIsShoulderOpen(false)} />
              ) : null}
              {menuSelected === "profile" && isLogged ? (
                <ProfileBox
                  onBachecasClick={() => setIsShoulderOpen(false)}
                  onSettingsClick={() => setMenuSelected("profile-settings")}
                  onPromoClick={() => setMenuSelected("promo")}
                />
              ) : null}
              {menuSelected === "profile-settings" ? (
                <SettingsBox onSaveClick={() => setMenuSelected("profile")} />
              ) : null}
              {menuSelected === "notifications" && !isLogged ? (
                <div className="d-flex flex-column align-items-center">
                  <p>{t("notifications.no_login_error")}</p>
                  <Button
                    text={t("auth.login")}
                    onClick={() => {
                      setMenuSelected("profile");
                    }}
                  />
                </div>
              ) : null}
              {menuSelected === "promo" ? <PromoBox /> : null}
              {menuSelected === "notifications" && isLogged ? (
                <NotificationBox
                  onSeeAllClick={() => {
                    dispatch(setNotificationNumber(0));
                  }}
                  onNotificationClick={(seen: boolean) => {
                    if (!seen)
                      dispatch(setNotificationNumber(notificationAmount - 1));
                    setIsShoulderOpen(false);
                  }}
                />
              ) : null}
              {menuSelected === "forgot-password-email" ? (
                <PasswordRecovery
                  passwordResetSuccess={() => {
                    logout();
                    setMenuSelected("profile");
                  }}
                />
              ) : null}
              {menuSelected === "profile" && !isLogged ? (
                <Auth forgotPasswordClick={forgotPasswordTab} />
              ) : null}
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </nav>
  );
}
