import { useRef, useState } from "react";
import searchSvg from "../../assets/svg/search.svg";
import { iBacheca, searchBachecasByName } from "../../services/bacheca";
import { debounce } from "lodash";
import Loader from "../Loader";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { datetimeToString } from "../../utils/generic";

const N_RESULTS_IN_SEARCH_BOX = 8;

const DEFAULT_IMAGE =
  "https://thegraphicsfairy.com/wp-content/uploads/2021/03/Antique-Family-Pic-GraphicsFairy.jpg";

export default function Searchbar() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [isResultOpen, setIsResultOpen] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [results, setResults] = useState<Array<iBacheca>>([]);

  const [isLocalLoading, setIsLocalLoading] = useState(false);

  const isQueryGood = (query: string) => {
    return /\S/.test(query) && query.length >= 1;
  };

  const searchCallback = async (query: any) => {
    try {
      const data = await searchBachecasByName(query);

      setResults(data.slice(0, N_RESULTS_IN_SEARCH_BOX));
    } catch (error) {
      console.log(error);
    }

    setIsLocalLoading(false);
  };
  const searchResultsDebounced = useRef(debounce(searchCallback, 500));

  const searchInput = async (e: any) => {
    const query = String(e.target.value);
    setIsResultOpen(true);
    setInputValue(query);

    if (!isQueryGood(query)) return setResults([]);

    searchResultsDebounced.current(query);
    setIsLocalLoading(true);
  };

  const searchMore = (query: string) => {
    setIsResultOpen(false);
    navigate("/search/" + query);
  };

  return (
    <div className="w100 search-input">
      <input
        style={{ height: "30px" }}
        value={inputValue}
        onInput={searchInput}
        onKeyUp={(e) => (e.key === "Enter" ? searchMore(inputValue) : null)}
        onFocus={() => {
          setIsResultOpen(true);
        }}
        onBlur={() => {
          setTimeout(() => {
            setIsResultOpen(false);
          }, 100);
        }}
        className="w100"
        type="text"
      />
      <div className="add-on">
        {isLocalLoading ? (
          <Loader scale={0.4} />
        ) : (
          <img src={searchSvg} alt="search-img" />
        )}
      </div>

      {isResultOpen ? (
        <div className="search-box">
          <div className="results-box">
            {results.length > 0
              ? results.map((res, key) => {
                  return (
                    <div
                      onClick={() => {
                        navigate("/bacheca/" + res._id);
                      }}
                      key={"result_" + key}
                      className="result"
                    >
                      <div className="d-flex">
                        <div className="">
                          <div
                            className="image"
                            style={{
                              backgroundImage: `url(${
                                res.profileImage ?? DEFAULT_IMAGE
                              })`,
                              backgroundPosition: "center center",
                              backgroundSize: "cover",
                            }}
                          ></div>
                        </div>
                        <div className="">
                          <div className="name">{res.name}</div>
                          <div className="info">
                            {datetimeToString(res.deathTime, true)} -{" "}
                            {datetimeToString(res.createdAt, true)}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              : ""}
          </div>

          <div onClick={() => searchMore(inputValue)} className="search-more">
            <img src={searchSvg} alt="search-icon" />
            <span className="text-capitalize">
              {t("search.search_more")} {inputValue}
            </span>
          </div>
        </div>
      ) : null}
    </div>
  );
}
