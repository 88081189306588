import phoneSvg from "../assets/svg/phone.svg";
import houseSvg from "../assets/svg/house.svg";
import mailSvg from "../assets/svg/mail.svg";

interface Props {
  light?: boolean;
}

export default function CompanyInfo({ light }: Props) {
  const data: Array<{ link: string; text: string; image: string }> = [
    // {
    //   link: "",
    //   image: houseSvg,
    //   text: "Country Road 56, New York, 23415",
    // },
    // {
    //   link: "",
    //   image: phoneSvg,
    //   text: "+39 123 3219 1239",
    // },
    {
      link: "mailto:info@memoramea.com",
      image: mailSvg,
      text: "info@memoramea.com",
    },
  ];

  return (
    <div className="company-info-container w100 d-flex flex-column d-md-block align-items-center">
      {data.map((e, key) => {
        return (
          <a
            href={e.link}
            target="_blank"
            rel="noreferrer"
            className={`${light ? "light" : ""}`}
            key={"c_info_" + key}
          >
            <img src={e.image} alt="contact" /> {e.text}
          </a>
        );
      })}
    </div>
  );
}
