import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import Loader from "../components/Loader";
import { iBacheca, searchBachecasByName } from "../services/bacheca";
import BachecaList from "../components/generic/BachecaList";

export default function Search() {
  const [results, setResults] = useState<Array<iBacheca>>([]);
  const [, setPage] = useState(1);
  const { query } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [isLocaleLoading, setIsLocaleLoading] = useState(false);
  const [isMoreButtonEnabled, setIsMoreButtonEnabled] = useState(true);

  const loadResults = async (myPage = 1, override = true) => {
    setIsLocaleLoading(true);
    try {
      const data = await searchBachecasByName(String(query), myPage);

      if (override) setResults(data);
      else setResults([...results, ...data]);

      if (data.length === 0) setIsMoreButtonEnabled(false);
    } catch (error) {
      console.log(error);
    }
    setIsLocaleLoading(false);
  };

  const viewMoreClick = () => {
    setPage((page) => {
      loadResults(page + 1, false);
      return page + 1;
    });
  };

  useEffect(() => {
    if (!query) navigate("");

    loadResults();
    // eslint-disable-next-line
  }, [location.pathname]);

  return (
    <main className="container mt-5">
      <BachecaList bachecas={results} onUpdate={() => {}} />
      {isMoreButtonEnabled ? (
        <div className="mt-5 see-all-btn d-flex justify-content-center">
          {!isLocaleLoading ? (
            <span onClick={viewMoreClick} className="cursor-pointer">
              {t("search.load_more")}
            </span>
          ) : (
            <Loader scale={0.8} />
          )}
        </div>
      ) : null}
    </main>
  );
}
