interface Props {
  scale?: number;
  light?: boolean;
}

export default function Loader({ scale, light }: Props) {
  return (
    <div
      style={{
        transform: `scale(${scale ?? 1})`,
      }}
      className={`loader ${light ? "light" : ""}`}
    >
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
}
