import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Button from "../dom/Button";

export default function HomeHeader() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const photos = [
    "https://res.cloudinary.com/df37ohxtp/image/upload/v1673258264/Website/16_uimd0e.jpg",
    "https://res.cloudinary.com/df37ohxtp/image/upload/v1673258264/Website/13_sfvzl9.jpg",
    "https://res.cloudinary.com/df37ohxtp/image/upload/v1673258264/Website/10_btjeww.jpg",
    "https://res.cloudinary.com/df37ohxtp/image/upload/v1673258264/Website/15_gqpniu.jpg",
    "https://res.cloudinary.com/df37ohxtp/image/upload/v1673258264/Website/14_yj3tow.jpg",
    "https://res.cloudinary.com/df37ohxtp/image/upload/v1673258264/Website/12_z3ijgh.jpg",
    "https://res.cloudinary.com/df37ohxtp/image/upload/v1673258264/Website/11_to7zeq.jpg",
    "https://res.cloudinary.com/df37ohxtp/image/upload/v1673258263/Website/7_ncmrec.jpg",
    "https://res.cloudinary.com/df37ohxtp/image/upload/v1673258263/Website/8_qmpnw1.jpg",
    "https://res.cloudinary.com/df37ohxtp/image/upload/v1673258263/Website/9_iyr6dc.jpg",
    "https://res.cloudinary.com/df37ohxtp/image/upload/v1673258263/Website/2_uqy5an.jpg",
    "https://res.cloudinary.com/df37ohxtp/image/upload/v1673258263/Website/6_j5l7nx.jpg",
    "https://res.cloudinary.com/df37ohxtp/image/upload/v1673258263/Website/0_ljqsgb.jpg",
    "https://res.cloudinary.com/df37ohxtp/image/upload/v1673258263/Website/3_nxl8tg.jpg",
  ];

  return (
    <header>
      <hgroup className="container">
        <h1 className="text-center lead">Memora Mea</h1>
        <h2 className="text-center display-1">{t("home.title")}</h2>
        <div className="d-flex flex-column flex-md-row gap-5 justify-content-center mt-5">
          <div className="text-center d-flex flex-column align-items-center justify-content-center">
            <p className="m-0">{t("home.create_question")}</p>
            <Button
              onClick={() => navigate("/bacheca/create")}
              text={t("home.create_cta")}
            />
          </div>
          <div className="text-center d-flex flex-column align-items-center justify-content-center">
            <p className="m-0">{t("home.join_partner_question")}</p>
            <Button
              onClick={() => navigate("/partners")}
              text={t("home.join_partner_cta")}
            />
          </div>
        </div>
      </hgroup>
      <div className="photos-container">
        {photos.map((p, key) => {
          if (key > photos.length / 2 - 1) return null;

          return (
            <div className="img-col" key={"img_" + key}>
              <div
                className="img"
                style={{
                  backgroundImage: `url(${photos[key]})`,
                  backgroundPosition: "center center",
                  backgroundSize: "cover",
                }}
              ></div>
              <div
                className="img"
                style={{
                  backgroundImage: `url(${photos[photos.length - key - 1]})`,
                  backgroundPosition: "center center",
                  backgroundSize: "cover",
                }}
              ></div>
            </div>
          );
        })}
      </div>
    </header>
  );
}
