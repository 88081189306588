import axios from "axios";
import { iUser } from "./auth";
import { iBacheca } from "./bacheca";
import { iContent } from "./content";
import { loadUserLikes } from "./like";

export interface iComment {
  _id: string;
  text: string;
  user: iUser;
  content: iContent | string;
  bacheca: iBacheca | string;
  images: Array<string>;
  createdAt: any;
  updatedAt: any;
  likeAmount: number;
}

export async function getCommentsOfBacheca(
  bacheca_id: string,
  page: number = 1
): Promise<Array<iComment>> {
  const response = await axios.get(
    `/bacheca/${bacheca_id}/comments?page=${page}`
  );

  await loadUserLikes({
    comment_ids: (response.data as Array<iComment>).map((e) => e._id),
  });

  return response.data;
}

export async function getCommentsOfContent(
  content_id: string,
  page: number = 1
): Promise<Array<iComment>> {
  const response = await axios.get(
    `/content/${content_id}/comments?page=${page}`
  );

  await loadUserLikes({
    comment_ids: (response.data as Array<iComment>).map((e) => e._id),
  });

  return response.data;
}

export async function createComment(payload: any): Promise<iComment> {
  const response = await axios.post("/comment", payload);
  return response.data;
}

export async function deleteComment(id: string): Promise<any> {
  const response = await axios.delete("/comment", { data: { comment_id: id } });
  return response.data;
}

export async function likeComment(id: string): Promise<any> {
  const response = await axios.post("/like", {
    comment_id: id,
  });
  return response.data;
}

export async function unlikeComment(id: string): Promise<any> {
  const response = await axios.delete("/like", {
    data: {
      comment_id: id,
    },
  });
  return response.data;
}
