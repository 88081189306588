import CompanyInfo from "./CompanyInfo";
import Links from "./Links";
import Logo from "./Logo";
import Socials from "./Socials";
import {
  LANGUAGE_BY_LOCALE,
  getLocaleLanguage,
  setLocaleLanguage,
} from "../services/i18next";
import Select from "./dom/Select";
import { useEffect, useState } from "react";

export default function Footer() {
  const languageOptions = Object.keys(LANGUAGE_BY_LOCALE);

  const [lang, setLang] = useState<string>();

  const changeLocaleLanguageSelect = (e: any) => {
    const newLang = e.target.value;
    setLang(newLang);
    setLocaleLanguage(newLang);
  };

  const initAsync = async () => {
    const l = await getLocaleLanguage();
    setLang(l);
  };

  useEffect(() => {
    initAsync();
  }, []);

  return (
    <footer>
      <div className="footer-container container">
        <div className="row">
          <div className="col-md d-flex d-md-block justify-content-center mb-4">
            <div>
              <Logo light />
              <a href="mailto:info@memoramea.com">info@memoramea.com</a>
            </div>
          </div>
          <div className="col-md d-flex d-md-block justify-content-center mb-4">
            <Links light />
          </div>
          <div className="col-md">
            <div className="d-flex d-md-block justify-content-center mb-4">
              <Select
                onChange={changeLocaleLanguageSelect}
                value={lang}
                className="text-uppercase"
                options={languageOptions.map((o) => {
                  return { value: o, text: o };
                })}
              />
            </div>
          </div>
          <div className="col-md">
            <div className="h100 d-flex flex-column justify-content-between">
              <div className="row">
                <div className="col p-0 px-1">
                  <div className="d-flex mb-4 ">
                    <Socials light />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md mb-4">
                  <CompanyInfo light />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-md">
            <div className="d-flex justify-content-center">
              Copyright @{new Date().getFullYear()}
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
