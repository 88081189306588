import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  AVATAR_DEFAULT,
  COVER_DEFAULT,
  logout,
  patchUser,
  refreshUserInfo,
  sendVerificaitonEmail,
} from "../../services/auth";
import { selectUser } from "../../store/userSlice";
import { compressImage } from "../../utils/generic";
import Button from "../dom/Button";
import Loader from "../Loader";

interface Props {
  onSettingsClick: Function;
  onPromoClick: Function;
  onBachecasClick: Function;
}

export default function ProfileBox({
  onSettingsClick,
  onPromoClick,
  onBachecasClick,
}: Props) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [, setIsLocaleLoading] = useState(false);
  const user = useSelector(selectUser);
  const [isProfileImageUploading, setIsProfileImageUploading] = useState(false);
  const [isCoverImageUploading, setIsCoverImageUploading] = useState(false);
  const [isVerificationEmailSent, setIsVerificationEmailSent] = useState(false);

  const logOutButton = async () => {
    setIsLocaleLoading(true);
    await logout();
    setIsLocaleLoading(false);
  };

  const sendVerificationEmailButton = async () => {
    setIsLocaleLoading(true);
    await sendVerificaitonEmail();
    setIsVerificationEmailSent(true);
    setIsLocaleLoading(false);
  };

  const profileImageInput = (e: any) => {
    try {
      const image = e.target.files[0];
      console.log(image);
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onloadend = async () => {
        try {
          setIsProfileImageUploading(true);
          const compressedImage = await compressImage(String(reader.result));
          console.log(compressedImage, reader.result);
          await patchUser({ profile_image: compressedImage });
          await refreshUserInfo();
        } catch (error) {}
        setIsProfileImageUploading(false);
      };
    } catch (error) {}
  };

  const coverImageInput = (e: any) => {
    try {
      const image = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onloadend = async () => {
        try {
          setIsCoverImageUploading(true);
          const compressedImage = await compressImage(String(reader.result));
          await patchUser({ cover_image: compressedImage });
          await refreshUserInfo();
        } catch (error) {}
        setIsCoverImageUploading(false);
      };
    } catch (error) {}
  };

  return (
    <section className="profile-box">
      <div className="cover-img-container">
        <input
          onChange={coverImageInput}
          type="file"
          name="cover_image"
          id="settings-cover-image"
          accept="image/png, image/jpeg"
        />

        <div
          onClick={() =>
            document.getElementById("settings-cover-image")?.click()
          }
          className="cover"
          data-text={t("profile.choose_image")}
          style={{
            backgroundImage: `url(${user.coverImage ?? COVER_DEFAULT})`,
            backgroundPosition: "center center",
            backgroundSize: "cover",
          }}
        >
          {isCoverImageUploading ? (
            <div className="cover-image-loading">
              <Loader light />
            </div>
          ) : null}
        </div>
      </div>
      <div className="profile-img-container">
        <input
          onChange={profileImageInput}
          type="file"
          name="profile_image"
          id="settings-profile-image"
          accept="image/png, image/jpeg"
        />
        {isProfileImageUploading ? (
          <div className="profile-image-loading">
            <Loader light />
          </div>
        ) : null}
        <div
          onClick={() =>
            document.getElementById("settings-profile-image")?.click()
          }
          className="avatar"
          data-text={t("profile.choose_image")}
          style={{
            backgroundImage: `url(${user.profileImage ?? AVATAR_DEFAULT})`,
            backgroundPosition: "center center",
            backgroundSize: "cover",
          }}
        ></div>
      </div>
      <p className="mt-4">
        {user.email}{" "}
        <i>
          {!user.emailVerified ? `(${t("profile.email_not_verified")})` : ""}
        </i>
      </p>

      {!user.emailVerified ? (
        <Button
          disabled={isVerificationEmailSent}
          onClick={sendVerificationEmailButton}
          small
          text={
            isVerificationEmailSent
              ? t("profile.sent_verification_email")
              : t("profile.send_verification_email")
          }
          className="mb-4"
        />
      ) : null}

      <Button
        className="my-1"
        onClick={() => {
          navigate("/bacheca/create");
          onBachecasClick();
        }}
        text={t("board.create")}
      />
      <br />
      <Button
        className="my-1"
        onClick={onSettingsClick}
        small
        text={t("profile.settings")}
      />
      <Button
        className="my-1"
        onClick={() => {
          navigate("channel/" + user._id);
          onBachecasClick();
        }}
        small
        text={t("profile.my_bachecas")}
      />
      <Button
        className="my-1"
        onClick={onPromoClick}
        small
        text={t("profile.promo")}
      />
      <div className="hr light mt-5"></div>
      <Button onClick={logOutButton} small text={t("profile.logout")} />
    </section>
  );
}
