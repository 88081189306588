import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Button from "../components/dom/Button";
import CustomDatePicker from "../components/dom/CustomDatePicker";
import Select from "../components/dom/Select";
import Loader from "../components/Loader";
import { isLoggedIn } from "../services/auth";
import {
  createPromoCode,
  deletePromoCode,
  DISCOUNT_TYPE,
  getPromoCodes,
  iPromoCode,
  PROMO_CODE_TYPE,
} from "../services/promocode";
import { selectUser } from "../store/userSlice";
import { datetimeToString, formToObject } from "../utils/generic";

export default function Promo() {
  const user = useSelector(selectUser);
  const navigate = useNavigate();
  const [, setPage] = useState(1);
  const [isLocaleLoading, setIsLocaleLoading] = useState(false);
  const [codes, setCodes] = useState<Array<iPromoCode>>([]);

  const loadCodes = async (myPage: number = 1, override: boolean = true) => {
    setIsLocaleLoading(true);

    try {
      const data = await getPromoCodes(myPage);

      if (override) setCodes(data);
      else setCodes([...codes, ...data]);
    } catch (error) {
      console.log(error);
    }
    setIsLocaleLoading(false);
  };

  const deleteCodeClick = async (id: string) => {
    setIsLocaleLoading(true);

    try {
      await deletePromoCode(id);

      setCodes((c) => {
        c = c.filter((e) => e._id !== id);
        return c;
      });
    } catch (error) {
      console.log(error);
    }
    setIsLocaleLoading(false);
  };

  const nextPageClick = () => {
    setPage((page) => {
      page++;
      loadCodes(page, false);
      return page;
    });
  };

  const codeFormSubmit = async (e: any) => {
    setIsLocaleLoading(true);

    try {
      const payload = formToObject(e.target);
      await createPromoCode(payload);

      await loadCodes();
    } catch (error) {
      console.log(error);
    }

    setIsLocaleLoading(false);
  };

  useEffect(() => {
    if (!isLoggedIn()) return navigate("/");

    if (user._id && !user.isAdmin) {
      navigate("/");
      return;
    }
    loadCodes();
    // eslint-disable-next-line
  }, [user]);

  if (!codes) return <></>;

  return (
    <main className="container">
      <form onSubmit={codeFormSubmit}>
        <label htmlFor="">code</label>
        <input type="text" name="code" />
        <label htmlFor="">expiry mode</label>
        <Select
          name="expiry_mode"
          options={Object.values(PROMO_CODE_TYPE).map((e) => {
            return { text: e, value: e };
          })}
        />
        <label htmlFor="">discount type</label>
        <Select
          name="discount_type"
          options={Object.values(DISCOUNT_TYPE).map((e) => {
            return { text: e, value: e };
          })}
        />
        <label htmlFor="">discount amount</label>
        <input name="discount_amount" type="number" step="0.01" min="0" />
        <label htmlFor="">max usages</label>
        <input name="max_usages" type="number" step="1" min="" />
        <label htmlFor="">expire date</label>
        <CustomDatePicker name="expire_date" />
        <Button loading={isLocaleLoading} type="submit" text="create" small />
      </form>
      <div className="hr dark"></div>
      <table className="w100 p-2">
        <thead>
          <tr>
            <th>code</th>
            <th>mode</th>
            <th>type</th>
            <th>disc. amount</th>
            <th>max usages</th>
            <th>times used</th>
            <th>expire date</th>
            <th>Delete</th>
          </tr>
        </thead>
        <tbody className="mt">
          {codes.map((code, key) => {
            return (
              <tr key={"code_" + key}>
                <th>{code.code}</th>
                <th>{code.expiryMode}</th>
                <th>{code.discountType}</th>
                <th>{code.discountAmount}</th>
                <th>{code.maxUsages}</th>
                <th>{code.usedOn.length}</th>
                <th>{datetimeToString(code.expireDate, true)}</th>
                <th>
                  <span
                    onClick={() => deleteCodeClick(code._id)}
                    className="text-danger p-2 cursor-pointer"
                  >
                    X
                  </span>
                </th>
              </tr>
            );
          })}
        </tbody>
      </table>
      {isLocaleLoading ? (
        <Loader />
      ) : (
        <div
          onClick={nextPageClick}
          className="text-center w100 mt-5 cursor-pointer"
        >
          load more
        </div>
      )}
    </main>
  );
}
